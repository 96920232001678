import { reactive } from 'vue'
import { persistedstate } from '../utils'
const url = "https://ykf-webchat.7moor.com/wapchat.html"
const param = {
  accessId:"867d9380-e6c3-11eb-b054-3fae9baa24ff",
  fromUrl:"https://b2b.cnhqt.com",
  urlTitle:"好货通",
  language:"ZHCN",
}
const getValue = (obj)=>{
  Object.keys(obj).map(v=>{
    urlQuery.push(v+'='+encodeURIComponent(params[v]))
  })
}

const state = persistedstate(
  'chat',
  {
    status: false,
    url: "",
    setStatus(value) {
      state.status = value
    },
    setUrl(value={}) {
      let params = {
        ...param,
        ...value
      }
      let urlQuery = []
      Object.keys(params).map(v=>{
        if(typeof  params[v] === 'object'){
          urlQuery.push(v+'='+encodeURIComponent(JSON.stringify(params[v])))
        }else{
          urlQuery.push(v+'='+encodeURIComponent(params[v]))
        }
      })
      console.log( url+'?'+urlQuery.join('&'))
      state.url = url+'?'+urlQuery.join('&')
    },
  },
  (state) => {
    const {status,url} = state
  }
)

export const chat = state
