<template>
  <VanButton class="button" :loading="loading.loading"  :disabled="disabled">
    <slot />
  </VanButton>
</template>

<script lang="ts">
import { defineComponent, watch } from 'vue'
import { Button } from 'vant'
import { useDelayLoading } from '@/utils'

export default defineComponent({
  name: 'Button',
  components: {
    VanButton: Button,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const loading = useDelayLoading(500)
    watch(() => props.loading, loading.set)

    return {
      loading,
    }
  },
})
</script>

<style lang="less" scoped>
.button {
  &.van-button--default {
    background-image: @primary-gradient;
    color: white;
    border-color: @c-primary;
  }

  &.van-button--disabled {
    background: rgba(#dbdada, 0.3);
    color: @c-grey-light;
    opacity: unset;
    border-color: rgba(#dbdada, 0.3);
  }
}
</style>
