<template>
  <Field
    class="input"
    :class="{ 'input--required': required }"
    v-bind="attrs"
    :autofocus="autofocus"
    @update:model-value="update"
    ref="field"
  >
    <template #label v-if="$slots.label">
      <slot name="label" />
    </template>
    <template #input v-if="$slots.input">
      <slot name="input" />
    </template>
    <template #left-icon v-if="$slots['left-icon']">
      <slot name="left-icon" />
    </template>
    <template #right-icon v-if="$slots['right-icon'] || clearable">
      <span
        class="right-icon__item"
        v-if="clearable && attrs.modelValue"
        @click="update('')"
      >
        <Icon class="clear" use="clear" />
      </span>

      <span
        class="right-icon__item"
        v-if="$slots['right-icon']"
        @click="clickRightIcon"
      >
        <slot name="right-icon" />
      </span>
    </template>
    <template #button v-if="$slots.button">
      <slot name="button" />
    </template>
    <template #extra v-if="$slots.extra">
      <slot name="extra" />
    </template>
  </Field>
</template>

<script lang="ts">
import { defineComponent, ref, toRefs, onMounted } from 'vue'
import { Field } from 'vant'
import Icon from '@/components/Icon'

export default defineComponent({
  name: 'Input',
  components: {
    Field,
    Icon,
  },
  props: {
    /* 显示清除按钮 */
    clearable: {
      type: Boolean,
      default: false,
    },

    /* 必填 */
    required: {
      type: Boolean,
      default: false,
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
  },
  emits: {
    'update:model-value': null,
    'click-right-icon': null,
  },
  setup(props, { attrs, emit }) {
    const field = ref()
    function update(value: string) {
      emit('update:model-value', value)
    }

    function clickRightIcon(e: MouseEvent) {
      emit('click-right-icon', e)
    }
    onMounted(() => {
      if (props.autofocus) {
        field.value?.focus()
      }
    })
    return {
      field,

      ...toRefs(props),
      attrs,

      update,
      clickRightIcon,
    }
  },
})
</script>

<style lang="less" scoped>
.input {
  &.van-cell {
    padding-top: rem(13);
    padding-bottom: rem(13);
  }
  &.van-cell--clickable {
    :deep(.van-field__control) {
      cursor: pointer;
    }
  }

  :deep(.van-field__control) {
    appearance: none;
    caret-color: @c-primary;
  }

  :deep(.van-field__clear) {
    padding: 0;
    margin-left: rem(-20);
  }

  :deep(.van-field__right-icon) {
    padding: 0;
    margin-right: rem(-4);
  }
  .right-icon__item {
    padding: 0 rem(4);
  }

  :deep(.van-field__control) {
    &[type='search']::-webkit-search-cancel-button {
      display: none;
    }
  }
}

.input--required {
  :deep(.van-field__label) {
    position: relative;
    &::after {
      content: ' ';
      color: @c-primary;
      display: inline-block;
      width: rem(7.5);
      height: rem(7.5);
      position: absolute;
      background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAYAAAByDd+UAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAJHSURBVHgB3VZdbtNAEJ61lwLBoHACmhvACWhPQHoCwlvV2op7giYnqKNsqr4lnAA4AblByw1yg1oiaRDx7jLTn2Bv1nE3qirE92bPzzez+81oAR4ZDBxxub+//cT3m4qxySshvoIjnAivyTg/x6D6dTBjSa3fP3LJ4bk4c85bd2QErXUMjnAifAg8OiE3f0wPD2PP895Irb+9FGIMG+BnGO74jH1QUv4ITk9HeVuhw6soOkEhnNDdoOH7LIqGJBS4J8h3FoZfKJZyMM8bXuF33qfQ4YoItG5toVDmYdj5xXkPssxOFMf1Z1nWlgAUXy+kAGhCWYeIC1tCBdDZWizOfYD3pm0aRS2ykQ8zyAhM60kpoVbqk+nwN5JtY7U7loRDsllDMJfSeq/wz+aIl97BSo5hc6TYcS/gPGFJklYSEuYoAOn7HRTRR3ABYyNvseg+PzubWM1V8USscJ2B5X4MUFd7VaO0dvAvDw7eas6H9yAj1FFU7aoxsnZIMn8q5fEmu/ImKxv9xmN9bTnWFUIc1HaZxJ2ACsWCu+amKRDObrZCExxA0tclY3FLMK4JsXv3vbxD2n9ryFKs9gg775qG2mDQQMLS+aXZnaIWVgiZUmlJQK/GeSMYDBIoQdDvj5iUu1jUZ6jAkhDP+iLfARKNcfO8C4SIzeG1geYOi2p5WdbAz/zTg+5xuTJXRDO/lbVtcG0b6IUQVqXTMfp4amYebqsUHgD5rvL4/58YToSoZBLDUkCkYHCE80OYRJXRc1HrdN2o/DP4A1vF/09NSIXuAAAAAElFTkSuQmCC');
      background-size: contain;
      background-position: center;
    }
  }
}
</style>
