import { AxiosResponse } from 'axios'
import { DeepReadonly, reactive, readonly, toRefs, UnwrapRef } from 'vue'
import { IAxiosResponse, isAxiosCancelError } from './http'

export interface IUseQueryOptions<T> {
  immediate?: boolean
  updateQuery?: (prev: T | null, current: T) => T | Promise<T>
  onError?: (error: Error, response: AxiosResponse<T> | null) => void
  keepDataOnRefetch?: boolean
}

const DEFAULT_UPDATE_QUERY = <T>(prev: T, current: T) => current

export function useQuery<T = object>(
  fetch: () => Promise<IAxiosResponse<T>>,
  options: IUseQueryOptions<T> = {}
) {
  const { immediate = true, updateQuery, onError, keepDataOnRefetch } = options

  const state = reactive<{
    data: T | null
    response: AxiosResponse<T> | null
    error: any | null
    loading: boolean
  }>({
    data: null,
    response: null,
    error: null,
    loading: false,
  })

  function clearState(keepData: boolean) {
    if (!keepData) state.data = null
    state.response = null
    state.error = null
  }

  /**
   * fetchKey 为解决多次请求存在取消请求时，loading 状态被覆盖的问题
   * 发起请求A后，再发起请求B
   * 请求B将请求A取消了，请求A的 finally 会将 loading 状态改为 false
   * 这会使 loading 与预期不一致
   */
  let fetchKey = 0

  async function fetchMore() {
    state.loading = true
    fetchKey = fetchKey < Number.MAX_SAFE_INTEGER ? fetchKey + 1 : 0
    const key = fetchKey

    try {
      const res = await fetch()
      state.response = res.response as UnwrapRef<AxiosResponse<T>>

      if (res.data) {
        const updated = (updateQuery ?? DEFAULT_UPDATE_QUERY)(
          state.data as T,
          res.data
        )
        state.data = (
          updated instanceof Promise ? await updated : updated
        ) as UnwrapRef<T>
      }

      if ('error' in res) state.error = res.error
    } catch (error) {
      if (isAxiosCancelError(error)) {
        state.error = null
      } else {
        state.error = error
      }
    } finally {
      if (key === fetchKey) {
        state.loading = false
      }
      if (state.error) {
        onError?.(state.error, state.response)
      }
    }
  }

  async function refetch() {
    clearState(!!keepDataOnRefetch)
    await fetchMore()
  }

  if (immediate) {
    refetch()
  }

  return reactive({
    ...toRefs(state),
    clearState,
    refetch,
    fetchMore,
  })
}

export interface IUseMutationOptions {
  onError?: (error: Error) => void
}

interface IUseMutationState<T> {
  data: T | null
  error: any | null
  loading: boolean
  called: boolean
}

export function useMutation<T = object>(
  mutation: () => Promise<IAxiosResponse<T>>,
  options: IUseMutationOptions = {}
): DeepReadonly<[() => Promise<IAxiosResponse<T>>, IUseMutationState<T>]> {
  const { onError } = options

  const state = reactive<IUseMutationState<T>>({
    data: null,
    error: null,
    loading: false,
    called: false,
  })

  async function mutate() {
    state.loading = true
    state.called = true
    state.data = null
    state.error = null

    try {
      const res = await mutation()

      if (res.data) {
        state.data = res.data as UnwrapRef<T>
      }

      if ('error' in res) state.error = res.error

      return res
    } catch (error) {
      if (isAxiosCancelError(error)) {
        state.error = null
      } else {
        state.error = error
      }
    } finally {
      state.loading = false
      if (state.error) {
        onError?.(state.error)
      }
    }
  }

  return readonly([mutate, state]) as DeepReadonly<
    [() => Promise<IAxiosResponse<T>>, IUseMutationState<T>]
  >
}
