<template>
  <VanList class="List" ref="listRef">
    <template #default v-if="$slots.default">
      <slot name="default" />
    </template>
    <!-- 底部加载中提示 -->
    <template #loading v-if="$slots.loading">
      <slot name="loading" />
    </template>
    <!-- 加载完成后的提示文案 -->
    <template #finished>
      <slot name="finished" v-if="$slots.finished" />
      <template v-else>
        <span class="text-line"></span>
        {{ finishedText }}
        <span class="text-line"></span>
      </template>
    </template>
    <!-- 加载失败后的提示文案 -->
    <template #error>
      <slot name="error" v-if="$slots.error" />
      <span v-else>
        <span class="text-line"></span>
        {{ errorText }}
        <span class="text-line"></span>
      </span>
    </template>
  </VanList>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import { List } from 'vant'

export default defineComponent({
  name: 'List',
  components: {
    VanList: List,
  },
  props: {
    ...({} as {
      error: BooleanConstructor
      loading: BooleanConstructor
      finished: BooleanConstructor
      loadingText: StringConstructor
      offset: {
        type: (NumberConstructor | StringConstructor)[]
        default: number
      }
      direction: {
        type: import('vue').PropType<'up' | 'down'>
        default: string
      }
      immediateCheck: {
        type: BooleanConstructor
        default: boolean
      }
    }),

    finishedText: {
      type: String,
      default: '没有更多了',
    },
    errorText: {
      type: String,
      default: '请求失败，点击重新加载',
    },
  },
  emits: {
    ...({} as { [key in 'load']: null }),
  },
  setup(props) {
    const listRef = ref<any>()
    function check() {
      listRef.value?.check?.()
    }

    return {
      check,
      listRef,
    }
  },
})
</script>

<style lang="less" scoped>
.text-line {
  display: inline-block;
  width: 1.14em;
  height: 1px;
  transform: scaleY(0.5);
  background-color: @c-grey-light;
  vertical-align: 0.3em;
  margin: 0 0.3em;
}
</style>
