<template>
  <Fixed position="bottom" :z-index="1500" :holder="holder">
    <div class="mynavbar" ref="el">
      <div class="mynavbar-appendix" v-if="$slots.default">
        <slot name="default" />
      </div>

      <Tabbar :model-value="active" :fixed="false" router v-if="!device.isWeChatMini">
        <MyNavbarIcon
          v-for="item in items"
          :key="item.label"
          :label="item.label"
          :icon="item.icon"
          :to="item.to"
          :dot="item.dot"
        />
      </Tabbar>
    </div>
  </Fixed>
</template>

<script lang="ts">
import { computed, ComputedRef, defineComponent, ref, toRefs, watch } from 'vue'
import { RouteLocationRaw, useRouter } from 'vue-router'
import { Tabbar, TabbarItem } from 'vant'
import Icon, { icons } from '../Icon'
import MyNavbarIcon from './MyNavbarItem.vue'
import Fixed from '../Fixed'

import { user,device } from '@/store'
import * as api from '@/api'

export type INavbarItemKey =
  | 'goods'
  | 'classify'
  | 'order'
  | 'purchase'
  | 'mine'

export interface INavbarItemData {
  key: INavbarItemKey
  label: string
  icon: keyof typeof icons
  to:
    | RouteLocationRaw
    | { to: RouteLocationRaw; text: string; dot?: ComputedRef<boolean> }[]
  dot?: ComputedRef<boolean>
}

let currentToken: typeof user.token

export default defineComponent({
  name: 'MyNavbar',
  components: {
    Tabbar,
    TabbarItem,
    Icon,
    MyNavbarIcon,
    Fixed,
  },
  props: {
    /* 生成等高的占位符 */
    holder: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    // 导航内容列表
    let items
    if (user.userData.is_business == 1 && user.userData.is_super == 0) {
      items = ref<INavbarItemData[]>([
        {
          key: 'goods',
          label: '主页',
          icon: 'home',
          to: {
            name: 'Goods',
          },
        },
        {
          key: 'classify',
          label: '分类',
          icon: 'classify',
          to: {
            name: 'Classify',
          },
        },
        {
          key: 'purchase',
          label: '采购单',
          icon: 'purchase',
          to: {
            name: 'Purchase',
          },
        },
        {
          key: 'mine',
          label: '我的',
          icon: 'mine',
          to: {
            name: 'Mine',
          },
          dot: computed(() => !!user.unread.refund),
        },
      ])
    } else {
      items = ref<INavbarItemData[]>([
        {
          key: 'goods',
          label: '主页',
          icon: 'home',
          to: {
            name: 'Goods',
          },
        },
        {
          key: 'classify',
          label: '分类',
          icon: 'classify',
          to: {
            name: 'Classify',
          },
        },
        {
          key: 'purchase',
          label: '采购单',
          icon: 'purchase',
          to: {
            name: 'Purchase',
          },
        },
        {
          key: 'order',
          label: '订单',
          icon: 'order',
          to: {
            name: 'OrderList',
          },
        },
        {
          key: 'mine',
          label: '我的',
          icon: 'mine',
          to: {
            name: 'Mine',
          },
          dot: computed(() => !!user.unread.refund),
        },
      ])
    }
    const router = useRouter()
    const navigation = computed(() => {
      return router.currentRoute.value.meta.navigation as INavbarItemKey
    })
    const active = computed(() => {
      return items.value.findIndex((item) => {
        return item.key === navigation.value
      })
    })

    watch(
      () => user.token,
      async (token) => {
        if (token === currentToken) return
        currentToken = token

        if (!token) {
          user.setUnread('refund', undefined)
          return
        }

        const { data, error } = await api.user.unread()
        api.errorProcessor({ error })

        if (data) {
          const { unread_refund_count } = data
          user.setUnread('refund', unread_refund_count)
        }
      },
      {
        immediate: true,
      }
    )

    return {
      ...toRefs(props),
      device,
      active,
      items,
    }
  },
})
</script>

<style lang="less" scoped>
.mynavbar {
  background-color: white;

  :deep(.van-tabbar-item) {
    font-size: rem(10);
  }
  :deep(.van-tabbar-item--active) {
    color: @primary;
  }

  :deep(.van-tabbar) {
    .safe-area-inset-bottom();
    background-color: transparent;
  }
}
</style>
