<template>
  <div class="User" v-if="pageStatus == 0">
    <div v-if="type == 1">
      <div class="UserHeader">
        <LogoIcon class="loginLogo" />
      </div>
      <div class="UserConter">
        <Input
          class="username"
          :class="nameFocus ? 'inputFocus' : ''"
          v-model="username"
          placeholder="请输入手机号"
          @focus="ChangeName(true)"
          maxlength="11"
          @blur="ChangeName(false)"
          clearable
        />
        <Input
          class="username"
          placeholder="填写短信验证码"
          v-model="password"
          maxlength="6"
          tabindex="6"
          clearable
        >
          <template #extra>
            <Button
              class="SmsBtn"
              size="small"
              :disabled="countdown.value > 0"
              @click="SendSms"
              type="danger"
            >
              {{
                countdown.value > 0
                  ? `已发送 (${countdown.value})`
                  : '获取验证码'
              }}
            </Button>
          </template>
        </Input>
        <Button
          class="loginBtn"
          size="large"
          @click="login"
          :disabled="username.length < 11 || password.length < 6"
          >登 录</Button
        >
        <div class="passwordBox">
          <div class="passwordType">
            <div class="typeLine"></div>
            <div class="typeContent">其他登录方式</div>
            <div class="typeLine"></div>
          </div>
          <div class="flex">
            <div class="changepassword" @click="type = 0">
              <img src="../../assets/image/lock.svg" />
              <div>账号密码</div>
            </div>
            <div
              class="changepassword scanlogin"
              @click="scanLogin"
              v-if="!isMobile"
            >
              <img src="../../assets/image/order_icon_saoma.svg" />
              <div>扫码登录</div>
            </div>
          </div>
        </div>
      </div>
      <div class="contactList" :class="{ 'contactList--center': !isDingTalk }">
        <CallDingtalk
          class="contactList-item"
          :user-id="service.userId"
          :dingtalk-id="service.dingtalkId"
          v-if="isDingTalk"
        >
          <Icon use="dingtalk" />在线钉钉
        </CallDingtalk>
        <CallMe class="contactList-item" :call="service.telephone">
          <Icon use="telephone" />客服电话
        </CallMe>
      </div>
      <div class="tips" style="text-align: center">
        <span class="tipsFont">如无法登录请联系您的品牌服务人开通账号</span>
      </div>
    </div>
    <div class="User" v-if="type == 0">
      <div class="UserHeader">
        <LogoIcon class="loginLogo" />
      </div>
      <div class="UserConter">
        <Input
          class="username"
          :class="nameFocus ? 'inputFocus' : ''"
          v-model="username"
          placeholder="请输入账号或手机号"
          @focus="ChangeName(true)"
          @blur="ChangeName(false)"
          clearable
        />
        <Input
          class="username"
          v-model="password"
          :type="shownewPassword ? 'text' : 'password'"
          @click-right-icon="shownewPassword = !shownewPassword"
          placeholder="请输入密码"
          clearable
        >
          <template #right-icon>
            <Icon class="eye" :use="shownewPassword ? 'eyeopen' : 'eyeclose'" />
          </template>
        </Input>
        <Button
          class="loginBtn"
          size="large"
          @click="login"
          :disabled="password.length < 6"
          >登 录</Button
        >
        <div class="changeType" @click="type = 2">忘记密码?</div>

        <div class="passwordBox">
          <div class="passwordType">
            <div class="typeLine"></div>
            <div class="typeContent">其他登录方式</div>
            <div class="typeLine"></div>
          </div>
          <div class="flex">
            <div class="changepassword" @click="type = 1">
              <img src="../../assets/image/account_sms.svg" />
              <div>短信验证码</div>
            </div>
            <div
              class="changepassword scanlogin"
              @click="scanLogin"
              v-if="!isMobile"
            >
              <img src="../../assets/image/order_icon_saoma.svg" />
              <div>扫码登录</div>
            </div>
          </div>
        </div>
        <div
          class="contactList"
          :class="{ 'contactList--center': !isDingTalk }"
        >
          <CallDingtalk
            class="contactList-item"
            :user-id="service.userId"
            :dingtalk-id="service.dingtalkId"
            v-if="isDingTalk"
          >
            <Icon use="dingtalk" />在线钉钉
          </CallDingtalk>
          <CallMe class="contactList-item" :call="service.telephone">
            <Icon use="telephone" />客服电话
          </CallMe>
        </div>
        <div class="tips" style="text-align: center">
          <span class="tipsFont">如无法登录请联系您的品牌服务人开通账号</span>
        </div>
      </div>
    </div>
    <div v-if="type == 2 && step == 'sms'">
      <div class="UserTitle">
        <Icon @click="type = 0" use="back" />
        <div>忘记密码</div>
      </div>
      <div class="UserConter">
        <Input
          class="username"
          :class="nameFocus ? 'inputFocus' : ''"
          v-model="username"
          placeholder="请输入手机号"
          @focus="ChangeName(true)"
          @blur="ChangeName(false)"
          clearable
        />
        <Input
          class="username"
          placeholder="填输入验证码"
          v-model="code"
          tabindex="6"
          maxlength="6"
          clearable
        >
          <template #extra>
            <Button
              class="SmsBtn"
              size="small"
              :disabled="countdown.value > 0"
              @click="SendSms"
              type="danger"
            >
              {{
                countdown.value > 0
                  ? `已发送 (${countdown.value})`
                  : '获取验证码'
              }}
            </Button>
          </template>
        </Input>
        <Button
          class="loginBtn"
          size="large"
          @click="submitSMS"
          :disabled="username.length < 11 || code.length < 6"
          >下一步</Button
        >
      </div>
    </div>
    <div v-if="type == 2 && step == 'edit'">
      <div class="UserTitle">
        <Icon @click="step = 'sms'" use="back" />
      </div>
      <NoticeBar
        text="为了您的账号安全，您修改密码后会将其他已登录该账号的设备进行踢出"
      />
      <Input label="用户名" v-model="account.account_name" readonly />
      <Input
        label="新密码"
        :type="eyes.password ? 'text' : 'password'"
        placeholder="填写新密码"
        v-model="password"
        @click-right-icon="eyes.password = !eyes.password"
      >
        <template #right-icon>
          <Icon class="eye" :use="eyes.password ? 'eyeopen' : 'eyeclose'" />
        </template>
      </Input>
      <Input
        label="确认密码"
        :type="eyes.confirmpassword ? 'text' : 'password'"
        placeholder="请再次输入新密码"
        v-model="confirm_password"
        @click-right-icon="eyes.confirmpassword = !eyes.confirmpassword"
      >
        <template #right-icon>
          <Icon
            class="eye"
            :use="eyes.confirmpassword ? 'eyeopen' : 'eyeclose'"
          />
        </template>
      </Input>
      <div class="SendSmsTips">长度为8-20位字符，包含数字+字母+特殊字符</div>
      <div class="button-outer">
        <Button @click="submit" block round> 保存 </Button>
      </div>
    </div>
  </div>
  <div class="User" v-if="pageStatus == 2">
    <Myheader title="信息补全" />
    <Input
      label="真实姓名"
      v-model="real_name"
      placeholder="请输入您的真实姓名"
      clearable
      required
    />
    <Input
      label="用户名"
      v-model="newUsername"
      placeholder="请输入4-16个字符的用户名"
      clearable
    />
    <Input
      label="密码"
      v-model="newPassword"
      :type="shownewPassword ? 'text' : 'password'"
      @click-right-icon="shownewPassword = !shownewPassword"
      placeholder="请设置至少6位数的密码"
      clearable
    >
      <template #right-icon>
        <Icon class="eye" :use="shownewPassword ? 'eyeopen' : 'eyeclose'" />
      </template>
    </Input>
    <div class="tips">
      <img src="../../assets/image/login_tishi.png" class="tipsImg" />
      <span class="tipsFont"
        >用户名只能包含字母、数字下划线（英文）不能是纯数字；未设置用户名时系统将自动进行默认</span
      >
    </div>
    <Button class="editUserName" @click="sendEdit">提交</Button>
  </div>
  <div class="SelectComponent" v-if="pageStatus == 3">
    <Myheader title="选择组织身份" />
    <div class="selectBox" v-if="company.length > 0">
      <div class="selectTitle">请选择组织进行登录</div>
      <div
        class="companyBox"
        v-for="item in company"
        :key="item.id"
        @click="selectCompany(item.id)"
      >
        <div class="companyTitle">
          {{ item.abbreviation ? item.abbreviation : item.name }}
        </div>
        <div class="companyContent">
          <Icon use="company" class="companyIcon" />{{ item.name }}
        </div>
      </div>
    </div>
    <div class="notCompany" v-if="!LoadingShow && !company[0]">
      <div class="notCompanyImg">
        <img src="../../assets/image/mine_image_comempty.svg" />
      </div>
      <p>
        未找到有效组织<br />
        请联系相关人员核实～
      </p>
      <Button @click="quitLogin">退出登录</Button>
    </div>
  </div>

  <Popup
    v-model:show="CodeShow"
    :close-on-popstate="true"
    closeable
    @close="close"
  >
    <div class="codeBg" @click="scanLogin">
      <img :src="codeImg" class="codeImg" v-if="time > 0" />
      <img src="@/assets/image/linkinvalid.png" class="codeImg" v-else />
      <div class="grayFont Canclick" v-if="time <= 0">点击刷新二维码</div>
      <div class="grayFont">使用微信或钉钉进行扫一扫快速登录</div>
      <div class="grayFont" v-if="time > 0">{{ time }}S后失效</div>
    </div>
  </Popup>
  <!-- 跳过 确认框 -->
  <Dialog
    v-model:show="showConfirm"
    title="提示"
    message="您也可以在个人中心-设置，修改密码。为了您的账号安全，请尽快修改。"
    show-cancel-button
    closeOnClickOverlay
    confirmButtonText="跳过"
    @confirm="jump"
  />
</template>

<script lang="ts">
import * as api from '../../api'
import { user, device } from '../../store'
import LogoIcon from './components/LogoIcon.vue'
import Icon from '@/components/Icon'
import Button from '../../components/Button'
import Input from '../../components/Input'
import CallMe, { CallDingtalk } from '@/components/CallMe'
import { LoadingToast } from '@/components/Loading'
import * as config from '@/config'
import { useCountdown, publicKey } from '@/utils'
const SMS_TIME_INTERVAL = 60000
import NoticeBar from '@/components/NoticeBar'
import Myheader from '@/components/MyHeader'
import { yup } from '@/utils'
import { Toast, Dialog, Popup } from 'vant'
import JSEncrypt from 'jsencrypt/bin/jsencrypt.min'
let setTime = null
const countdown = useCountdown({
  format(value) {
    return Math.floor(value / 1000)
  },
})
export default {
  name: 'Login',
  components: {
    Button,
    Input,
    Icon,
    LogoIcon,
    CallMe,
    CallDingtalk,
    Myheader,
    NoticeBar,
    Toast,
    LoadingToast,
    Popup,
  },
  data() {
    return {
      username: '',
      password: '',
      btnFont: '登录',
      btnSize: 'large',
      nameFocus: false,
      passwordFocus: false,
      eye: false,
      isMobile: device.isMobile,
      isDingTalk: device.isDingTalk,
      ...config.contact,
      type: user.loginType, // 0 账号登录  1验证码登录  2忘记密码
      pageStatus: 0, // 0 登录  2 首次登录 密码  3 选择组织
      company: [],
      is_need_select_company: false,
      newUsername: '',
      newPassword: '',
      shownewPassword: false,
      step: 'sms',
      account: [],
      confirm_password: '',
      eyes: {
        password: false,
        confirmpassword: false,
      },
      code: '',
      countdown,
      LoadingShow: false,
      showConfirm: false,
      real_name: '',
      selectType: 0,
      CodeShow: false,
      codeImg: '',
      key: '',
      time: 0,
    }
  },
  methods: {
    //登录按钮请求方法
    async login() {
      const encryptor = new JSEncrypt()
      encryptor.setPublicKey(publicKey)
      let _params = encryptor.encrypt(
        JSON.stringify({
          username: this.username,
          password: this.password,
          type: this.type,
        })
      )
      const { error, data } = await api.user.login({
        _params_: _params,
      })
      user.setloginType(this.type)
      api.errorProcessor({ error })

      if (data) {
        console.log('aaaa')
        user.setToken(data.token)
        /*        if (!data.is_set_password) {
          user.setSelectCompany(true)
          this.pageStatus = 2
          this.selectType = data.is_need_select_company
        } else*/ if (!data.is_real_name) {
          user.setSelectCompany(true)
          this.pageStatus = 2
        } else if (data.is_need_select_company == 40006) {
          this.is_need_select_company = true
          this.$router.push({ path: '/Goods' })
        } else if (data.is_need_select_company == 40007) {
          user.setUserData()
          user.setToken()
          Toast('此用户没有所属组织')
        } else {
          user.setSelectCompany(true)
          this.$router.push({ path: '/Goods' })
        }
      }
    },
    ChangeName(type: boolean) {
      this.nameFocus = type
    },
    ChangePassword(type: boolean) {
      this.passwordFocus = type
    },
    toggleEye() {
      this.eye = !this.eye
    },
    async getAccountCompany() {
      this.LoadingShow = true
      const { data, error } = await api.user.getAccountCompany()
      api.errorProcessor({ error, throwError: false })
      this.LoadingShow = false
      this.company = data
    },
    async SendSms() {
      if (this.username.length < 11) {
        Toast('请输入正确手机号')
        return
      }
      if (this.countdown.value <= 0) {
        const { data, error } = await api.user.sendCode({
          phone: this.username,
        })
        api.errorProcessor({ error })
        this.countdown.run(SMS_TIME_INTERVAL)
      }
    },
    async selectCompany(id) {
      const { data, error } = await api.user.selectCompany({ id: id })
      api.errorProcessor({ error, throwError: false })
      user.setSelectCompany(true)
      this.$router.push({ path: '/Goods' })
    },
    quitLogin() {
      user.setUserData()
      this.pageStatus = 0
    },
    async sendEdit() {
      const { data, error } = await api.user.firstEdit({
        real_name: this.real_name,
        login_name: this.newUsername,
        password: this.newPassword,
      })
      api.errorProcessor({ error })
      if (this.is_need_select_company) {
        this.pageStatus = 3
      } else {
        if (this.selectType == 40007) {
          user.setUserData()
          user.setToken()
          this.pageStatus = 3
        } else {
          user.setSelectCompany(true)
          this.$router.push({ path: '/Goods' })
        }
      }
    },
    jump() {
      if (this.is_need_select_company) {
        this.pageStatus = 3
      } else {
        user.setSelectCompany(true)
        this.$router.push({ path: '/Goods' })
      }
    },
    async submitSMS() {
      const { data, error } = await api.user.codeSubmit({
        phone: this.username,
        code: this.code,
      })
      api.errorProcessor({ error })
      if (data) {
        this.step = 'edit'
        this.account = data
      }
    },
    async submit() {
      let password = Number(this.password)
      if (isNaN(password)) {
        if (this.password !== this.confirm_password) {
          Toast('两次密码不相同')
        }
        const { data, error } = await api.user.submitEdit({
          password: this.password,
          confirm_password: this.confirm_password,
          ticket: this.account.ticket,
          account_id: this.account.account_id,
        })
        api.errorProcessor({ error })
        Toast('修改密码成功')
        this.type = 1
        this.password = ''
        this.confirm_password = ''
      } else {
        Toast('密码长度为6-20位，且至少需要1个英文字母或特殊字符')
      }
    },
    close() {
      clearTimeout(setTime)
      this.time = 0
    },
    async scanLogin() {
      if (this.time > 0) {
        return false
      }
      const { data, error } = await api.other.getCode()
      api.errorProcessor({ error })
      this.codeImg = data.data
      this.key = data.key
      this.CodeShow = true
      this.check()
    },
    async check() {
      const { data, error } = await api.other.check({
        key: this.key,
      })
      api.errorProcessor({ error })
      if (!data.token) {
        if (data.time > 0) {
          setTime = setTimeout(() => {
            this.check()
          }, 1000)
        }
        this.time = data.time
      } else {
        user.setToken(data.token)
        user.setSelectCompany(true)
        clearTimeout(setTime)
        this.$router.push({ path: '/Goods' })
      }
    },
  },
}
</script>

<style lang="less" scoped>
@import '../../styles/index.less';
.User {
  min-height: 100vh;
  background-color: white;
  display: flex;
  flex-direction: column;
}
.UserHeader {
  flex-shrink: 0;
}
.UserConter {
  flex-grow: 1;
}
.loginLogo {
  width: rem(280);
  height: rem(104);
  margin-top: rem(53);
  margin-left: rem(47);
  margin-right: rem(48);
}
.username {
  font-size: rem(16);
  margin: rem(20);
  height: rem(58);
  width: calc(~'100% - ' rem(40));
  padding: rem(16) 0;
  caret-color: #f7685d;
  padding-left: 2px;
}
.username::after {
  width: 100%;
  right: 0;
  left: 0;
  border-bottom: 1px solid #d3d1d1;
  transform: scaleY(0.5);
}
.password::after {
  width: 100%;
  right: 0;
  left: 0;
  border-bottom: 1px solid #d3d1d1;
  transform: scaleY(0.5);
}
.password {
  margin: rem(20);
  border: 0;
  padding: rem(16) 0;
  font-size: rem(16);
  width: calc(~'100% - ' rem(40));
  caret-color: #f7685d;
  padding-left: 2px;
}
@supports (-webkit-mask: none) and (not (cater-color: #f7685d)) {
  .username {
    color: #333333;
  }
  .password {
    color: #333333;
  }
  .username::first-line {
    color: #f7685d;
  }
  .password::first-line {
    color: #f7685d;
  }
}
.inputFocus::after {
  border-bottom: 1px solid #fa5e3d;
  transform: scaleY(0.5);
}
.loginBtn {
  border-radius: 900px;
  color: #ffffff;
  height: rem(46);
  width: calc(~'100% - ' rem(40));
  margin: rem(17) rem(20);
  font-weight: 600;
  font-size: rem(17);
  margin-bottom: rem(17);
}
.showPassword {
  width: rem(18);
  height: auto;
  z-index: 111;
}
.tips {
  line-height: 0;
  margin: 0 rem(20);
}
.tipsImg {
  width: rem(12);
  height: rem(12);
  margin: 0 rem(5);
}
.tipsFont {
  font-size: rem(12);
  color: #999999;
}
.contactList {
  display: flex;
  flex-shrink: 0;
  justify-content: space-between;
  margin: rem(20);
  margin-bottom: rem(8);
  margin-top: rem(50);
  .safe-area-inset-bottom();
}

.contactList--center {
  justify-content: center;
}

.contactList {
  :deep(.contactList-item) {
    position: relative;
    padding-right: rem(23);
    padding-left: rem(50);
    line-height: rem(46);
    background-color: #f4f4f4;
    border-radius: rem(23);
    font-size: rem(16);
    color: @c-black-light;

    @icon-margin: rem(4);
    > .icon {
      border-radius: 50%;
      background-color: #ff7445;
      color: white;
      position: absolute;
      font-size: rem(46) - @icon-margin * 2;
      left: @icon-margin;
      top: @icon-margin;
    }
  }
}
.SmsBtn {
  font-size: rem(15);
  background: rgba(204, 204, 204, 0.3);
  border-radius: rem(40);
  border: none;
  color: @c-black-heavy;
  font-weight: 400;
  margin-top: -2px;
}
.selectBox {
  padding: 0 rem(20);
}
.selectTitle {
  margin: rem(35) 0;
  font-weight: 600;
  font-size: rem(27);
  line-height: 38px;
  color: #4b4b5a;
}
.companyBox {
  margin-bottom: rem(20);
  border-radius: 4px;
  overflow: hidden;
  .companyTitle {
    font-size: rem(16);
    color: #ffffff;
    padding: rem(10) rem(16);
    background: #9ea1a6;
  }
  .companyIcon {
    font-size: rem(20);
    margin-right: rem(8);
  }
  .companyContent {
    font-size: rem(15);
    color: #333333;
    background: #ffffff;
    padding: rem(20) rem(16);
  }
}
.notCompany {
  display: flex;
  flex-direction: column;
  font-size: rem(15);
  color: #9c9ca5;
  align-items: center;
  text-align: center;
  Button {
    width: rem(246);
    border-radius: 450px;
  }
}
.notCompanyImg {
  margin-top: rem(136);
  img {
    width: rem(163);
    height: rem(163);
  }
}
.editUserName {
  margin: rem(20);
  margin-top: rem(36);
  border-radius: 900px;
  font-size: rem(17);
}
.jumpBtn {
  font-size: rem(17);
  text-align: center;
}
.changeType {
  font-size: rem(14);
  text-align: right;
  margin-right: rem(20);
}
.loginTitle {
  margin-top: rem(35);
  margin-left: rem(20);
  font-size: rem(27);
  font-weight: 600;
}
.UserTitle {
  display: flex;
  align-items: center;
  padding-left: rem(14);
  height: rem(44);
  font-size: rem(18);
  border-bottom: 1px solid rgba(232, 236, 239, 0.5);
}

.BindCardBox {
  min-height: 100vh;
  background: white;
  box-sizing: border-box;

  .BindTitle {
    font-size: rem(26);
    color: #524f4f;
    line-height: 1.4;
    padding: rem(30) rem(16);
    padding-bottom: 0;
  }
  .BindContent {
    margin-top: rem(4);
    padding: 0 rem(16);
    font-size: rem(16);
    line-height: 1.4;
    color: rgba(@c-black-light, 0.45);
  }
  .BindInputList {
    &[class*='van-hairline']::after {
      margin: 0 rem(16);
    }

    :deep(.van-cell) {
      font-size: rem(16);
    }

    :deep(.van-field__label) {
      width: 5em;
    }

    :deep(.van-field__control) {
      font-weight: 600;

      &::placeholder {
        font-weight: 400;
      }
    }
  }
  .BindCardBtnBox {
    padding: rem(32.5) rem(16);
    .BindCardBtn {
      display: block;
      width: 100%;
      font-weight: 500;
    }
  }
  .SmsBtn {
    font-size: rem(14);
    background: rgba(@c-grey-light, 0.3);
    border-color: rgba(@c-grey-light, 0.3);
    border-radius: rem(40);
    color: @c-black-heavy;
    font-weight: 600;
    margin-top: -2px;
  }
}

.relegation {
  :deep(.spinner-point) {
    font-size: rem(12);
    color: @c-grey;
  }
  .error {
    color: @c-fail;
  }
}
.SendSmsContent {
  padding: rem(16);
  margin-top: rem(8.5);
  font-size: rem(14);
  color: #999999;
}
.button-outer {
  margin: rem(15);
  margin-top: rem(32);
  font-weight: 500;
}

.eye {
  font-size: rem(18);
}

.editpassword-input {
  :deep(.van-field__label) {
    width: 4.2em;
  }
}
.SendSmsTips {
  font-size: rem(14);
  color: #999999;
  padding: 0 rem(16);
  padding-top: rem(16);
}
.passwordType {
  display: flex;
  justify-content: center;
  align-items: center;
  .typeLine {
    width: rem(112);
    height: 1px;
    background: rgba(153, 153, 153, 0.5);
    transform: scaleY(0.5);
  }
  .typeContent {
    color: #999999;
    font-size: rem(14);
    margin: 0 rem(13);
  }
}
.changepassword {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: rem(11);
  color: #4b4b5a;
  margin-top: rem(16);
  img {
    width: rem(44);
    height: rem(44);
    margin-bottom: rem(4);
  }
}
.scanlogin {
  margin-left: rem(60);
}
.codeBg {
  padding: rem(40);
}
.codeImg {
  width: rem(200);
  height: rem(200);
}
.grayFont {
  text-align: center;
  margin-top: rem(10);
  font-size: rem(12);
  color: #999999;
}
.Canclick {
  cursor: pointer;
}
</style>
