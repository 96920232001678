import { http, httpPay, IAxiosRequestConfig } from './http'
import { IUser } from '@/api'
import { IAddress, IApiBindBankCard } from './types'

/* 银行卡 */
export function bindBankCard(parmas: {
  accountId: IUser['id']
  bankAccountName: string
  bankAccountNumber: string
  bankID: string
  bankPhoneNumber: string
  branchName: string
  credentialNumber: string
}) {
  return httpPay.post<IApiBindBankCard>('/bindCard', parmas)
}
export function bindBankCardConfirm(parmas: { sMSCode: string; txSN: string }) {
  return httpPay.post('/bindCardConfirm', parmas)
}
export function unbindBankCard(params: { accountId: number; bindId: number }) {
  return httpPay.post('/cancelBindCard', params)
}

/* 地址 */
export function addresses(
  params: { goods_id?: string | number } = {},
  options: IAxiosRequestConfig
) {
  return http
    .get<IAddress[]>('/my/shop_address', {
      params,
      ...options,
    })
    .then((res) => {
      if (res.data) {
        res.data = res.data.filter(({ status }) => {
          return status == 1
        })

        res.data.map((item) => {
          const { mobile, address, linkman } = item
          item.info_complete = Boolean(mobile && address && linkman)
          return
        })
      }

      if (!res.error) {
        res.data = res.data ?? []
      }

      return res
    })
}

/* 新增地址、修改地址 */
export function updateAddress(params: {
  shop_id?: number
  name: string
  province: string
  city: string
  district: string
  town: string
  address: string
  is_default: 0 | 1
  mobile: string
  linkman: string
}) {
  return http.post(
    params.shop_id ? '/shop/edit_shop' : '/shop/add_shop',
    params,
    {
      loading: true,
    }
  )
}

/* 删除地址 */
export function removeAddress(params: { shop_id: number }) {
  return http.get('/shop/del_shop', { params, loading: true })
}

export function lastAddress(params: { goods_id?: string | number } = {}) {
  return http.get<{
    shop_id: number
  }>('/my/get_last_shop', {
    params,
  })
}

/* 计算运费 */
export function getFreightFee(params: {
  shop_id: string
  goods?: {
    id: number
    goods_art_no: string
    amount: number
  }[]
}) {
  return http.post('/order/compute/express/freight', params, {
    loading: true,
  })
}
