<template>
  <Fixed position="top" :holder="holder" :zIndex="zIndex">
    <div :class="['myheader', `myheader--${theme}`, 'van-hairline--bottom']">
      <NavBar
        :title="title"
        :right-text="rightText"
        @click-right="clickRight"
        v-if="!isDingTalk && !hiddenTitle && show"
      >
        <template #left>
          <div class="myheader-back" v-if="backButton" @click="goBack">
            <Icon use="back" />
          </div>
          <slot name="left" />
        </template>

        <template #title v-if="$slots.title">
          <slot name="title" />
        </template>

        <template #right v-if="$slots.right">
          <slot name="right" />
        </template>
      </NavBar>

      <div class="myheader-appendix" v-if="$slots.default">
        <slot />
      </div>
    </div>
  </Fixed>
</template>

<script lang="ts">
import {
  defineComponent,
  onActivated,
  onDeactivated,
  onMounted,
  onUnmounted,
  computed,
  PropType,
  toRefs,
  watch,
} from 'vue'
import { useRouter } from 'vue-router'
import { NavBar } from 'vant'
import Icon from '../Icon'
import Fixed from '../Fixed'
import { device } from '@/store'

export type IMyHeaderTheme = 'default' | 'primary'

export default defineComponent({
  name: 'MyHeader',
  components: {
    NavBar,
    Icon,
    Fixed,
  },
  props: {
    /* 标题 */
    // 属性废弃 将由 device.title + 路由插件 setTtile 代替
    title: {
      type: String,
    },

    /* 隐藏标题栏 */
    hiddenTitle: {
      type: Boolean,
      default: false,
    },

    /* 主题，可使用的值： default, primary */
    theme: {
      type: String as PropType<IMyHeaderTheme>,
      default: 'default',
    },

    /* 显示返回按钮 */
    backButton: {
      type: Boolean,
      default: false,
    },

    /* 生成等高的占位符 */
    holder: {
      type: Boolean,
      default: true,
    },

    /* 右侧文字按钮的内容 */
    rightText: String,

    zIndex: Number,
  },
  emits: {
    /* 点击返回按钮时的事件 */
    // 虽然注释了，但这个监听时存在的，
    // 当存在这个监听事件，则会覆盖默认的后退。
    // clickBack: null,
    /* 右侧文字按钮的点击事件 */
    // 同上
    // rightText: null,
  },
  setup(props, { attrs }) {
    const { isDingTalk, title, isWeChatMini } = toRefs(device)

    const show = computed(() => {
      return !isWeChatMini.value
    })

    const router = useRouter()
    function goBack() {
      if ('onClickBack' in attrs) {
        ;(attrs.onClickBack as Function)()
      } else {
        router.back()
      }
    }

    function clickRight() {
      if ('onClickRight' in attrs) {
        ;(attrs.onClickRight as Function)()
      }
    }

    function setRightTextButton(text?: string) {
      device.setRightTextButton(
        text
          ? {
              text,
              callback: clickRight,
            }
          : undefined
      )
    }

    watch(() => props.rightText, setRightTextButton, {
      immediate: true,
    })

    onActivated(() => {
      setRightTextButton(props.rightText)
    })

    onUnmounted(() => {
      setRightTextButton()
    })
    onDeactivated(() => {
      setRightTextButton()
    })

    onMounted(() => {
      try {
        /* 属性废弃警告 */
        if (props.title && __IS_DEV__) {
          console.warn(
            '组件 MyHeader 的属性 [title] 已被废弃，请停止使用该属性，如需自定义标题，请通过 slot.#title 或 store.device.setTitle 代替。'
          )
        }
      } catch (e) {}
    })

    return {
      title,

      goBack,
      isDingTalk,
      show,
      clickRight,
    }
  },
})
</script>

<style lang="less" scoped>
.myheader {
  &::after {
    border-color: @grey-4;
  }
  :deep(.van-hairline--bottom)::after {
    content: unset;
  }
  :deep(.van-nav-bar__left) {
    padding: 0;
  }
  :deep(.van-nav-bar__right) {
    padding: 0;
  }
  :deep(.van-nav-bar__text) {
    padding: 0 rem(16);
  }
  :deep(.van-nav-bar__title) {
    font-size: rem(17);
    font-weight: 500;
  }

  /* 返回按钮 */
  &-back {
    height: 100%;
    padding: 0 rem(15);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: rem(18);
  }

  :deep(.van-nav-bar) {
    background-color: transparent;
  }

  /* 主题 */
  &--default {
    color: black;
    background-color: white;

    :deep(.van-nav-bar__title) {
      color: black;
    }
  }

  &--primary {
    color: white;
    background-image: @primary-gradient;

    :deep(.van-nav-bar__title) {
      color: white;
    }

    :deep(.van-hairline--bottom)::after {
      border-color: @primary;
    }
  }
}
</style>
