import { RouteLocation, Router } from 'vue-router'

export const REDIRECT_KEY = 'redirectTo'

/* 需要记录重定向的路由 */
const INCLUDES = ['Login', 'SelectCompany']

/* 不需要添加到来源路径的路由 */
const EXCLUEDS = ['Settings', 'Ready']

/* 进入该页面 需要被重定向 */
const REDIRECTS = ['Goods']

function has(routeNames: string[], route: RouteLocation) {
  return routeNames.includes(route.name as string)
}

export function getRedirectTo(route: RouteLocation) {
  let redirectTo = route.query?.[REDIRECT_KEY] as string | undefined
  return redirectTo && decodeURIComponent(redirectTo)
}

export function createRedirectTo(route: RouteLocation) {
  let redirectTo = getRedirectTo(route)
  redirectTo ??= has(INCLUDES, route) ? undefined : route.fullPath
  return redirectTo && encodeURIComponent(redirectTo)
}

/**
 * 登录后 重定向
 * @param router
 */
export function loginRedirect(router: Router) {
  let isRedirecting = false
  let lastTo: RouteLocation

  router.beforeEach((to, from, next) => {
    /* 保存重定向后拿到的路由信息，用于避免循环重定向 */
    if (isRedirecting) {
      isRedirecting = false
      lastTo = to
    }

    /* 添加来源路径 */
    if (!has(INCLUDES, to)) return next()
    if (!from.name || has(EXCLUEDS, from)) return next()

    if (getRedirectTo(to)) return next()

    const redirectTo = to.name !== from.name && createRedirectTo(from)
    if (!redirectTo) return next()

    const query = {
      ...to.query,
      [REDIRECT_KEY]: redirectTo,
    }
    const newRoute = {
      ...to,
      query,
    }
    return next(newRoute)
  })

  /* 重定向 */
  router.beforeEach((to, from, next) => {
    if (to === lastTo) return next() // 防止重复重定向

    if (!has(REDIRECTS, to)) return next()
    if (!has(INCLUDES, from)) return next()

    const redirectTo = getRedirectTo(from)
    if (redirectTo) {
      isRedirecting = true
      next(redirectTo)
    } else {
      next()
    }
  })
}
