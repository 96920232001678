export default [

  {
    path: '/other/designreview/:id',
    name: 'designReview',
    component: () => import('@/views/Other/DesignReview/index.m.vue'),
    meta: {
      title: '设计评审',
    },
  },
  {
    path: '/other/designreview/detail/:id',
    name: 'designReviewDetail',
    component: () => import('@/views/Other/DesignReview/detail.m.vue'),
    meta: {
      title: '详情-设计评审',
    },
  },
  {
    path: '/other/designreview/draft/:id',
    name: 'designReviewDraft',
    component: () => import('@/views/Other/DesignReview/draft.m.vue'),
    meta: {
      title: '发表意见-设计评审',
    },
  },
]


