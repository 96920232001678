import { IApiGoodsDetail, IApiHotSale } from './types'
import { http } from './http'
import { purchase } from '@/store'
import { addresses } from './payment'

export async function getGoodsList(params: any, loading?: boolean) {
  //自营商品列表 查库存 需要携带门店ID   前端未判断是否是自营，交给后端判断
  let shop_id = purchase.currentShop
  if (!shop_id) {
    const res = await addresses()
    if (res.data[0]) {
      purchase.setCurrentShop(res.data[0]?.shop_id)
      shop_id = purchase.currentShop
    }

  }
  return http.get('/goods/index', {
    params: {
      ...params,
      shop_id: shop_id,
    },
    cancelRepeatKey: `${Date.now}${Math.random()}`,
    loading,
  })
}

export function getTopClass(params: any, loading?: boolean) {
  return http.get('/goods/top_class', { params, loading })
}

export function getGroupTopClass(params: any, loading?: boolean) {
  return http.get('/goods/group_top_class', { params, loading })
}

export function getGoodsDetail(params: any) {
  return http.get<IApiGoodsDetail>('/goods/detail', { params }).then((res) => {
    if (res.data) {
      const data = res.data
      const {
        price,
        retail_price,
        concession_price,
        images,
        art_price,
        sku_image,
        futures,
      } = data

      data.price = toArray(price)
      data.art_price = toArray(art_price)
      data.retail_price = toArray(retail_price).filter((v) => !!(v * 1))
      data.concession_price = toArray(concession_price)
      data.images = toArray(images)
      data.sku_image = toArray(sku_image)

      function toNumber(value: any) {
        const res = Number(value ?? 0)
        return Number.isNaN(res) ? 0 : res
      }

      if (futures) {
        futures.skus?.forEach((v) => {
          v.sale_count = toNumber(v.sale_count)
          v.min_manu_amount = toNumber(v.min_manu_amount)
        })
        futures.price = toArray(futures.price)
        futures.concession_price = toArray(futures.concession_price)
        futures.futures_price = toArray(futures.futures_price)
      }
    }
    return res
  })
}

export function getGoodSku(params: any) {
  return http.get('/goods/detail/skus', { params })
}

function toArray<T>(value: T | T[]) {
  return Array.isArray(value) ? value : [value]
}

export function getNewGoods(params: any) {
  return http.get('/goods/new_goods', { params })
}

export function getSpecialGoods(params: any) {
  return http.get('/goods/special_goods', { params })
}

/* 生成二维码 */
export function createQrcode(params: any) {
  return http.post('/other/create_qrcode', params)
}

export function hotsales(params: any, loading?: boolean) {
  return http.get('/goods/ranking_goods', { params, loading })
}
export function hotsalesRestail(params: any, loading?: boolean) {
  return http.get('/goods/retail_goods', { params, loading })
}

export function hotsaleSearch() {
  return http.get('/goods/retail_goods_search', {
    params: {
      is_ranking: 1,
    },
  })
}

/* 获取首页 */
export function getIndex() {
  return http.get('/index')
}
//获取期货商品列表
export function getFuturesGoods(params: any, loading?: boolean) {
  return http.get('/goods/futures_goods', { params, loading })
}

//获取期货详情图
export function getFuturesIndex(params: any, loading?: boolean) {
  return http.get('/index/futures', { params, loading })
}

//获取首页轮播图
export function getBannerIndex() {
  return http.get('/index/ad_rotation')
}

//获取首页轮播图
export function getActiveIndex() {
  return http.get('/index/get_active_status')
}

//获取期货首页
export function getFutureIndex() {
  return http.get('/index/get_futures')
}

//获取商品分组首页
export function getGroupIndex() {
  return http.get('/index/get_group')
}

//获取直播首页
export function getLiveIndex() {
  return http.get('/index/get_live', { loading: false })
}

//获取分组信息
export function getGroupDetail(params: any) {
  return http.get('/index/group_row', { params })
}

//获取开发款式期货商品列表
export function getRunFutures(params: any) {
  return http.get('/goods/futures_goods_running', { params })
}

//获取风格大类，商品子类
export function getStyle(params: any) {
  return http.get('/goods/style_class', { params, loading: false })
}

//大中小类
export function getSysStyle() {
  return http.get('/goods/get_sys_class', { loading: false })
}

//热卖榜筛选信息
export function getHotSelect() {
  return http.get('/goods/select_info', { loading: false })
}

/* 样品评审 列表 */
export function reviewList(params: any) {
  return http.get('/review/index', { params })
}

/* 样品评审 提交分数 */
export function addReview(params: any) {
  return http.post('/review/add_review', params)
}

/* 样品评审 详情 */
export function reviewDetail(params: any) {
  return http.get('/review/detail', { params })
}
/* 货号列表 */
export function goodsArtList(params: any) {
  return http.get('/goods/goods_art_list', { params })
}

/* 扫码盘点 列表 */
export function scanCheckList(params: any) {
  return http.get('/inventory/index', { params, loading: false })
}

/* 扫码盘点 详情 */
export function scanCheckDetail(params: any) {
  return http.get('/inventory/detail', { params })
}

/* 扫码盘点 新建 */
export function createScanCheck(params: any) {
  return http.post('/inventory/create', params, {
    loading: false,
  })
}

/* 扫码盘点 删除 */
export function removeScanCheck(params: any) {
  return http.post('/inventory/delete', params)
}

/* 扫码盘点 修改货号 */
export function editScanCheckDetail(params: any) {
  return http.post('/inventory/save_detail', params, {
    loading: false,
  })
}

/* 扫码盘点 删除行 */
export function removeScanCheckDetail(params: any) {
  return http.post('/inventory/delete_detail', params)
}

//期货补单排行列表
export function getFutureRank(params: any, loading = true) {
  return http.get('/goods/future_repair_ranking', { params, loading })
}

//期货补单分类
export function getClassInfo(params: any) {
  return http.get('/goods/get_class_info', { params })
}

//零售排行榜总榜
export function getRetailRank() {
  return http.get('/ranking/retail/index')
}

//零售排行榜详情
export function getRetailDetail(params: any) {
  return http.get('/ranking/retail/details', { params })
}

//批发排行榜总榜
export function getWholesaleRank() {
  return http.get('/ranking/wholesale/index')
}

//批发排行榜详情
export function getWholesaleDetail(params: any) {
  return http.get('/ranking/wholesale/details', { params })
}

//批发排行榜总总榜
export function getTotalRetailRank(params: any) {
  return http.get('/ranking/wholesale/total/index', { params })
}

//零售排行榜总总榜
export function getTotalWholesaleRank(params: any) {
  return http.get('/ranking/retail/total/index', { params })
}

//签收列表
export function getSeadledList(params: any) {
  return http.get('/produce/sealed_sample/list', { params })
}

/* 扫码盘点 删除行 */
export function seadledSigned(params: any) {
  return http.post('/produce/sealed_sample/sealed_sample_signed', params)
}

// 子类筛选项
export function getRankScreen() {
  return http.get('/ranking/get_screen')
}

//获取AI创款商品
export function getAiGoodsList(params: any) {
  return http.get('/goods/get_ai_goods_list', { params })
}


// 能否购买代销商品
export function getItBuyPermission(params: {
  goods_id?: number | string,
  goods_art_nos?: number | string,
}) {
  return http.get('/goods/get_it_buy_permission', { params })
}



/* 评分详情（员工） */
export function getOwnerDetailList(params: any) {
  return http.get('/review/owner_detail_list', {
    params,
    loading: false,
  })
}