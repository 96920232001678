import { user } from '@/store/user'

const THOUSAND_SPEPARATOR = ','

function thousandSeparator(n: number) {
  const symbol = n < 0 ? '-' : ''
  n = Math.floor(Math.abs(n))
  let count = 0
  let ans = ''
  do {
    let cur = n % 10
    n = Math.floor(n / 10)
    ans += cur.toString()
    ++count
    if (count % 3 == 0 && n) {
      ans += THOUSAND_SPEPARATOR
    }
  } while (n)
  return symbol + ans.split('').reverse().join('')
}

/**
 * 格式化价格
 * @param n 价格
 * @param fractionDigits 保留小数位数
 * @returns 如 123,456.00 的字符串
 */
export function formatPrice(
  n: number | string,
  options?: {
    fractionDigits?: number
    skipAuth?: boolean
    removeTailZero?: boolean
  }
) {
  const { fractionDigits = 2, skipAuth, removeTailZero } = options ?? {}
  if (!skipAuth && !user.userData?.is_see) return '--'

  if (typeof n === 'string') n = Number(n)
  if (Number.isNaN(n)) n = 0

  const intger = thousandSeparator(n)
  if (fractionDigits === 0) return intger
  let fraction = n.toFixed(fractionDigits).slice(-fractionDigits)
  if (removeTailZero) {
    fraction = fraction.replace(/0+$/, '')
  }

  return fraction ? `${intger}.${fraction}` : intger
}

export function formatByLength(
  value: string,
  splitLength: number[],
  separator = ' '
) {
  let last = value
  let res = []
  const _splitLength = splitLength.concat()

  while (last) {
    const length = _splitLength.shift()
    if (length) {
      res.push(last.substr(0, length))
      last = last.substr(length)
    } else {
      res.push(last)
      last = ''
    }
  }

  return res.join(separator)
}



//加
export function floatAdd(arg1,arg2){
  var r1,r2,m,n;
  try{r1=arg1.toString().split(".")[1].length}catch(e){r1=0}
  try{r2=arg2.toString().split(".")[1].length}catch(e){r2=0}
  m=Math.pow(10,Math.max(r1,r2));
  //动态控制精度长度
  n=(r1>=r2)?r1:r2;
  return Number(((arg1*m+arg2*m)/m).toFixed(n));
}

//减
export function floatSub(arg1,arg2){
  var r1,r2,m,n;
  try{r1=arg1.toString().split(".")[1].length}catch(e){r1=0}
  try{r2=arg2.toString().split(".")[1].length}catch(e){r2=0}
  m=Math.pow(10,Math.max(r1,r2));
  //动态控制精度长度
  n=(r1>=r2)?r1:r2;
  return ((arg1*m-arg2*m)/m).toFixed(n);
}

//乘
export function floatMul(arg1,arg2)   {
  var m=0,s1=arg1.toString(),s2=arg2.toString();
  try{m+=s1.split(".")[1].length}catch(e){}
  try{m+=s2.split(".")[1].length}catch(e){}
  return Number(s1.replace(".","")) * Number(s2.replace(".","")) /Math.pow(10,m);
}

//除
export function floatDiv(arg1,arg2){
  var t1=0,t2=0,r1,r2;
  try{t1=arg1.toString().split(".")[1].length}catch(e){}
  try{t2=arg2.toString().split(".")[1].length}catch(e){}

  r1=Number(arg1.toString().replace(".",""));

  r2=Number(arg2.toString().replace(".",""));
  return (r1/r2)*Math.pow(10,t2-t1);
}


