import { biz, config as configBase, env, ready, runtime } from 'dingtalk-jsapi'
export { biz, env, ready, runtime }

import { http } from '@/api/http'

/* 需要使用的 鉴权 jsapi */
const jsApiList = ['biz.chat.openSingleChat']

export let corpId = ''

let isConfiged = false // 鉴权完成状态
let currentConfigPromise: Promise<void> | null = null // 鉴权时的 Promise

/**
 * 鉴权
 * config().then(() => { 此处调用需要鉴权的 api })
 */
export function config() {
  if (currentConfigPromise) return currentConfigPromise

  currentConfigPromise = new Promise<void>(async (resolve, reject) => {
    if (isConfiged) return resolve()
    if (!env.version) return reject()

    getConfig().then((res) => {
      const data = res.data!

      corpId = data.corpId

      configBase({
        agentId: data.agentId,
        corpId: data.corpId,
        timeStamp: data.timeStamp,
        nonceStr: data.nonceStr,
        signature: data.signature,

        // 需要使用的 jsapi 列表
        jsApiList,
      })

      isConfiged = true
      resolve()
    }, reject)
  })

  currentConfigPromise.finally(() => {
    currentConfigPromise = null
  })

  return currentConfigPromise
}

function getConfig() {
  const { origin, pathname, search } = location
  const url = `${origin}${pathname}${search}`

  return http
    .get<{
      agentId: string
      corpId: string
      timeStamp: number
      nonceStr: string
      signature: string
      url: string
    }>('/other/jsapi', {
      params: {
        url,
      },
    })
    .then((res) => {
      if (res.data) {
        res.data.url = url
      }

      return res
    })
}
