import { Router, useRoute } from 'vue-router'
import { device } from '@/store'

/**
 * 小程序路由拦截
 */
export function weChat(router: Router) {
  //window.history.length > 之前的
  router.beforeEach((to, from, next) => {
    /*
     *  window.history.state.replaced 替换
     *  window.history.state.forward 后退
     *  miniPagetype === 1  好货通模式 （路由走小程序）
     *  路由走小程序的时候，query有applet参数，则跳转页面直接跳转到小程序地址，否则跳转小程序webview嵌套H5地址
     *  miniPagetype  === 2 好订宝模式（路由走H5）
     * */
    if (device.isWeChatMini  && device.miniPagetype === 1) {
      let navigateTo = wx.miniProgram.navigateTo
      let isTab = false
      if (
        ['/Classify', '/Goods', '/OrderList', '/Mine', '/Purchase'].includes(
          to.fullPath
        )
      ) {
        navigateTo = wx.miniProgram.switchTab
        isTab = true
      }
      switch (to.name) {
        //登录
        case 'Login':
          wx.miniProgram.navigateTo({ url: '/pages/index/index' })
          return
        default:
          let url =
            '/pages/webview/index?page=' + encodeURIComponent(to.fullPath)
          if ((to.query.applet && to.meta.weappPath) || isTab) {
            url = '/' + to.meta.weappPath
            let query = ''
            Object.keys(to.query).map((item, index) => {
              if (item !== 'applet') {
                if (index > 0) {
                  query += '&' + item + '=' + to.query[item]
                } else {
                  query += '?' + item + '=' + to.query[item]
                }
              }
            })
            url += query
          } else if (to.query.redirect) {
            url = '/pages/webview/index?page='
            let query = ''
            Object.keys(to.query).map((item, index) => {
              if (item !== 'redirect') {
                if (index > 0) {
                  query += '&' + item + '=' + to.query[item]
                } else {
                  query += '?' + item + '=' + to.query[item]
                }
              }
            })
            url += encodeURIComponent('/' + to.name + query)
          }
          if (from.name) {
            if (window.history.state.replaced) {
              if (window.history.state.position > 1) {
                wx.miniProgram.redirectTo({ url: url })
              } else {
                if (to.query.redirect) {
                  wx.miniProgram.redirectTo({ url: url })
                } else {
                  navigateTo({ url: url })
                }
              }
            } else if (window.history.state.forward) {
              wx.navigateBack()
            } else {
              console.log(url)
              if (to.query.redirect) {
                wx.miniProgram.redirectTo({ url: url })
              } else {
                navigateTo({ url: url })
              }
            }

            return
          }
      }

      return next()
    }
    return next()
  })

  router.afterEach((to) => {})
}
