import * as store from '@/store'
import { Router } from 'vue-router'
import { chat } from '@/store'
import { ossResize } from '@/utils'

function OSSImgUrl(url) {
  if (url) {
    return ossResize(url, {
      m: 'fill',
      w: 200,
      h: 200,
    })
  }
  return url
}

function setUser() {
  window.qimoClientId = {
    userId: store.user.userData.id, // 自定义用户的唯一id，不能传空字符串、null、中文，id由字母大小写、数字及'-'和 '_'组成
    priority: false, //是否优先进线设置，非必填，值为boolean类型的true或false
    refreshUnreopen: true,
    nickName: store.user.userData.account_name, //自定义用户昵称，定义用户昵称则userId必传
    customField: {
      用户正式姓名: store.user.userData.real_name,
      用户手机号: store.user.userData.phone,
      企业客户编号: store.user.userData.brand_company_code,
      企业名称: store.user.userData.brand_company_name,
    },
  }
}

//打开会话
export function openChat() {
  window.m7ProductInfo = {}
  setUser()
  window.qimoChatClick()
}

//发送铺货请求
export function openChatDistribution(name) {
  window.m7ProductInfo = {
    visible: true,
    actionText: '发送',
    actionTextColor: '#fff',
    title: '您好，我希望平台能够上架“' + name + '”',
    sub_title: '',
    img: 'https://huilima.oss-cn-hangzhou.aliyuncs.com/frontend/hqt/wechat/sharp.jpg',
    price: '',
    target: window.location.href,
    time: 'agent',
    showCardInfoMsg: '1',
  }
  console.log(name)

  setUser()
  window.qimoChatClick()
}
//打开商品
export function openChatGoods(detail) {
  window.m7ProductInfo = {
    visible: true,
    actionText: '发送',
    actionTextColor: '#fff',
    title: detail.goods_title,
    sub_title: '货号：' + detail.goods_number,
    img: detail.images[0],
    price: '￥' + detail.concession_price,
    target: window.location.href,
    time: 'agent',
    showCardInfoMsg: '1',
  }

  setUser()
  window.qimoChatClick()
}

//打开订单
export function openChatOrder(detail, type = 1) {
  if (type == 1) {
    window.m7ProductInfo = {
      visible: true,
      actionText: '发送',
      actionTextColor: '#fff',
      title: detail.number ? '订单号：' + detail.number : '',
      sub_title: detail.created_at ? '下单时间：' + detail.created_at : '',
      img: detail.order_detail.skus[0].goods_image
        ? OSSImgUrl(detail.order_detail.skus[0].goods_image)
        : 'https://huilimaimg.cnhqt.com/frontend/favicon.png',
      price: detail.pay_money ? '￥' + detail.pay_money : '',
      target: window.location.href,
      time: 'agent',
      showCardInfoMsg: '1',
    }
  } else {
    window.m7ProductInfo = {
      visible: true,
      actionText: '发送',
      actionTextColor: '#fff',
      title: detail.refund_number ? '退款单号：' + detail.refund_number : '',
      sub_title: detail.created_time ? '申请时间：' + detail.created_time : '',
      img: detail.order_detail.goods_image
        ? OSSImgUrl(detail.order_detail.goods_image)
        : 'https://huilimaimg.cnhqt.com/frontend/favicon.png',
      price: detail.refund_launch_money
        ? '￥' + detail.refund_launch_money
        : '',
      target: window.location.href,
      time: 'agent',
      showCardInfoMsg: '1',
    }
  }
  setUser()
  window.qimoChatClick()
}

//获取未读消息
export function getUnreadMsgCount() {
  return new Promise((resolve) => {
    try {
      window.getUnReadMsgCount(store.user.userData.id, function (o) {
        setUser()
        if (o.message == 'Loading is not complete, please wait') {
          setTimeout(() => {
            getUnreadMsgCount()
          }, 1000)
        } else {
          const count = o.count || 0
          resolve(count)
        }
      })
    } catch (e) {
      setTimeout(() => {
        getUnreadMsgCount()
      }, 1000)
    }
  })
}

/**/
export function closeChatBox(router: Router) {
  router.beforeEach((to, from, next) => {
    if (window.qimosdk) window.qimosdk.closeChatBox()
    next()
  })
}
