import { isReactive, reactive, watch, toRaw } from 'vue'
import { UnwrapNestedRefs } from '@vue/reactivity'
import merge from 'deepmerge'

interface IStorage {
  getItem: (key: string) => any
  setItem: (key: string, value: any) => void
}

export interface IPersistedstateOptions {
  storage?: IStorage
  baseKey?: string
}

function persistedstateFactory(options: IPersistedstateOptions = {}) {
  const { storage = window.localStorage, baseKey = '' } = options

  function createKey(key: string) {
    return baseKey + key
  }

  return function <T extends object>(
    key: string,
    target: T,
    sources: (state: UnwrapNestedRefs<T>) => object
  ): UnwrapNestedRefs<T> {
    const reactiveTarget = (isReactive(target)
      ? target
      : reactive(target)) as UnwrapNestedRefs<T>

    /* 监听对象变化，并存入 storage */
    watch(
      () => sources(reactiveTarget),
      (value) => setState(createKey(key), value, storage)
    )

    /* 获取 storage 的值，进行合并 */
    const rawTarget = toRaw(target)
    const savedState = getState(createKey(key), storage)
    if (typeof savedState === 'object' && savedState !== null) {
      const merged = merge(rawTarget, savedState, {
        arrayMerge: function (store, saved) {
          return saved
        },
        clone: false,
      })

      // FIXME: 期望合并进原始对象，暂时用这种糟糕的方式处理，有空再更换 merge 方法。
      Object.assign(rawTarget, merged)
    }

    return reactiveTarget
  }
}

/**
 * 创建一个可持久化的对象
 * @param {string} key - 存入 storage 的键值
 * @param {object} target 目标对象
 * @param {fuction} source 返回需要存入 storage 的数据，亲按原来的层级返回
 * @return {object} reactive(target)
 */
export const persistedstate = persistedstateFactory({
  baseKey: 'hqt',
})

function getState(key: string, storage: IStorage) {
  const value = storage.getItem(key)

  try {
    return typeof value !== 'undefined' ? JSON.parse(value) : undefined
  } catch (err) {}

  return undefined
}

function setState(key: string, state: object, storage: IStorage) {
  return storage.setItem(key, JSON.stringify(state))
}
