<template>
  <router-link
    class="ShopItem"
    :to="{
      name: 'GoodsDetail',
      query: {
        id: id,
        keyword: keyword,
      },
    }"
  >
    <div class="ShopImgBox">
      <Image
        :src="image"
        :lazyLoad="true"
        class="ShopImg"
        :oss-resize="{
          m: 'fill',
          w: '346',
          h: '346',
        }"
      />
      <img
        src="@/assets/image/bofang.svg"
        class="playImg"
        v-if="detail.video"
      />
      <div class="presellstatus">
        <template v-if="is_futures == 1 || detail.is_futures == 1">
          <div class="tag-futures_small">期货</div>
        </template>
        <template v-else-if="is_pre_sales == 1 || detail.is_pre_sales == 1">
          <div class="tag-pre_sale_small">现货预售</div>
        </template>
        <template v-if="detail.is_oem == 1">
          <div class="tag-oem_small">贴牌</div>
        </template>
      </div>
    </div>
    <div class="ShopTitle">{{ goods_title }} {{ goods_number }}</div>
    <Prices
      class="ShopPrice"
      :value="price"
      size="m"
      diffSize
      decimalStyle="all"
      bold
    />
    <div class="ShopStock">
      <span
        v-if="detail.labels && detail.labels.length > 0"
        class="product-tag-icon"
        >{{ detail.labels[0] && detail.labels[0].label_name }}</span
      >
      <span> 剩余{{ type }} {{ stock_amount }}件 </span>
    </div>
    <div class="ShopStock">
      {{ company.abbreviation || company.name || '' }}
    </div>
  </router-link>
</template>
<script lang="ts">
import { defineComponent, toRefs } from 'vue'
import Image from '@/components/Image'
import Prices from '@/components/Prices'

export default defineComponent({
  components: {
    Image,
    Prices,
  },
  props: {
    id: Number,
    detail: Object,
    image: String,
    is_pre_sales: Number,
    is_futures: Number,
    goods_title: String,
    keyword: String,
    goods_number: String,
    price: [String, Array],
    type: {
      type: String,
      default: '',
    },
    company: {
      type: Object,
    },
    stock_amount: Number,
  },
  setup(props) {
    return {
      ...toRefs(props),
    }
  },
})
</script>

<style scoped lang="less">
@space: rem(5);
.ShopItem {
  border-radius: rem(4);
  width: calc(~'50% - ' @space * 2);
  margin-left: @space * 2;
  justify-content: space-around;
  background-color: #ffffff;
  margin-bottom: rem(9);
}
.ShopImgBox {
  width: 100%;
  position: relative;
  height: 0;
  padding-bottom: 100%;
}
.presellstatus {
  position: absolute;
  display: flex;
  top: rem(5);
  right: rem(3);
}
.ShopImg {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.ShopTitle {
  color: #333333;
  margin-top: rem(12);
  margin-bottom: rem(8);
  margin-left: rem(10);
  font-size: rem(13);
  text-overflow: ellipsis;
  width: rem(156);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ShopPrice {
  display: block;
  margin-bottom: rem(8);
  margin-left: rem(10);
  width: rem(156);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ShopStock {
  color: #9c9c9c;
  font-size: rem(11);
  margin-left: rem(10);
  margin-bottom: rem(8);
  width: rem(156);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.tag-futures_small {
  background: #8ad937;
  font-size: rem(9);
  border-radius: rem(2);
  color: #ffffff;
  padding: rem(2);
  z-index: 10;
  margin-left: rem(4);
}
.tag-oem_small {
  background: linear-gradient(232deg, #e52b16 9%, #feb619 93%);
  font-size: rem(9);
  border-radius: rem(2);
  color: #ffffff;
  padding: rem(2);
  z-index: 10;
  margin-left: rem(4);
}
// 播放icon
.playImg {
  position: absolute;
  left: rem(10);
  bottom: rem(10);
  width: rem(20);
  height: auto;
}
.product-tag-icon {
  display: inline-block;
  font-size: rem(10);
  border-radius: rem(2);
  color: rgba(245, 108, 108, 1);
  background-color: rgba(255, 255, 255, 1);
  border: rem(0.5) solid rgba(245, 108, 108, 1);
  padding: rem(1) rem(2);
  margin-right: rem(4);
}
</style>
