<template>
  <TabbarItem
    class="navbar-item"
    :to="to && !Array.isArray(to) ? to : undefined"
    @click="showPopover = true"
  >
    <span>{{ label }}</span>
    <template #icon>
      <Popover
        v-if="actions.length"
        :show="showPopover"
        placement="top"
        @click-overlay="showPopover = false"
        overlay
        :overlay-style="{ background: 'transparent' }"
      >
        <div
          class="navbar-item__popover-action"
          v-for="action in actions"
          :key="action.text"
          @click="onSelect(action)"
        >
          {{ action.text }}

          <!-- dot -->
          <svg
            class="navbar-item__dot"
            viewBox="0 0 44 44"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            v-if="action.dot"
          >
            <circle cx="22" cy="22" r="20" fill="currentColor" />
            <circle
              cx="22"
              cy="22"
              r="15"
              stroke="white"
              stroke-opacity="0.9"
              stroke-width="1"
            />
          </svg>
        </div>

        <template #reference>
          <div></div>
        </template>
      </Popover>

      <Icon :use="icon" />

      <!-- dot -->
      <svg
        class="navbar-item__dot"
        viewBox="0 0 44 44"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        v-if="dot"
      >
        <circle cx="22" cy="22" r="20" fill="currentColor" />
        <circle
          cx="22"
          cy="22"
          r="15"
          stroke="white"
          stroke-opacity="0.9"
          stroke-width="1"
        />
      </svg>
    </template>
  </TabbarItem>
</template>

<script lang="ts">
import { computed, ComputedRef, defineComponent, PropType, ref } from 'vue'
import { useRouter, RouteLocationRaw } from 'vue-router'
import { TabbarItem, Popover } from 'vant'
import Icon, { icons } from '../Icon'

export default defineComponent({
  name: 'MyNavbarItem',
  props: {
    label: {
      type: String,
      required: true,
    },
    icon: {
      type: String as PropType<keyof typeof icons>,
      required: true,
    },

    dot: {
      type: Boolean,
      default: false,
    },

    to: {
      type: [Object, Array] as PropType<
        | RouteLocationRaw
        | { to: RouteLocationRaw; text: string; dot?: ComputedRef<boolean> }[]
      >,
    },
  },
  components: {
    TabbarItem,
    Icon,
    Popover,
  },
  setup(props) {
    const router = useRouter()

    const showPopover = ref(false)
    const actions = computed(() => {
      if (!props.to || !Array.isArray(props.to)) return []
      return props.to.map((v) => ({
        text: v.text,
        to: v.to,
        dot: v.dot,
      }))
    })

    function onSelect(action: { text: string; to: RouteLocationRaw }) {
      router.push(action.to)
      showPopover.value = false
    }

    return {
      showPopover,
      actions,
      onSelect,
    }
  },
})
</script>

<style lang="less" scoped>
.navbar-item {
  :deep(.icon) {
    font-size: 0.44rem;
  }

  :deep(.van-badge--dot) {
    background-color: @c-primary;
    margin-top: 0;

    &::before {
      content: ' ';
      display: block;
      position: absolute;
      box-sizing: border-box;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) scale(0.2);

      width: rem(6 * 5);
      height: rem(6 * 5);
      border-radius: 50%;
      border: 1px solid white;
    }
  }

  :deep(.van-popover__wrapper) {
    display: block;
    transform: translate(0, rem(-10));
  }
}

.navbar-item__dot {
  position: absolute;
  right: 0;
  top: 0;
  transform: translate(50%, -50%);
  color: @c-primary;

  width: rem(8);
  height: rem(8);
}

.navbar-item__popover-action {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: rem(128);
  height: rem(44);
  padding: 0 rem(16);
  font-size: rem(14);
  line-height: rem(20);
  cursor: pointer;

  &:active {
    background-color: rgba(black, 0.05);
  }

  .navbar-item__dot {
    position: static;
  }
}
</style>
