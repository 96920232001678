<template>
  <!--  <div style="background: #fff">
      <MyHeader title="订货明细" back-button zIndex="1555"></MyHeader>
    </div>-->

  <div class="demo-warp">
  <div
    class="tableBox"
  >
    <div v-for="i in 2" :class="i === 1 ? 'titleBox' : 'titleScroll'" :ref="'scroll'+i">
      <table :border="0" cellspacing="0">
        <thead class="headerTop" :style="{ opacity: i === 1 ? 1 : 0 }">
        <tr>
          <th class="headerItem" nowrap>
            <div class="chaochu_1 width-120">货号</div>
          </th>
          <th class="headerItem" nowrap>
            <div class="chaochu_1 width-60">单价</div>
          </th>
          <th class="headerItem" nowrap>
            <div class="chaochu_1 width-40">数量</div>
          </th>
          <th class="headerItem" nowrap>
            <div class="chaochu_1 width-80">小计</div>
          </th>
          <th
            class="headerItem"
            nowrap
            v-for="item in state.sizes"
            :key="item"
          >
            <div class="chaochu width-80">{{ item }}</div>
          </th>
          <!--          <th class="headerItem" nowrap>
                      <div class="chaochu_1 width-120">门店</div>
                    </th>-->
        </tr>
        </thead>
        <tbody class="analysisBody" :style="{ opacity: i === 2 ? 1 : 0 }">
        <tr
          class="tableItem"
          v-for="(item, index) in state.tableData"
          :key="item"
        >
          <td class="contentItem" nowrap @click="openSku(item,index)">

            <img
              v-if="item.goods_art_no !== '合计'"
              class="AnalysisOrderDetailImage"
              :src="item.url"
            />

            <div class="chaochu width-120 goods_name">
              {{ item.goods_art_no }}
            </div>

          </td>
          <td class="contentItem" nowrap>
            <div class="width-60" v-if="item.goods_art_no !== '合计'">￥{{ item.unit_price }}</div>
          </td>
          <td class="contentItem" nowrap>
            <div class="width-40">{{ item.sku_amount }}</div>
          </td>
          <td class="contentItem" nowrap>
            <div class="width-80">￥{{ item.price }}</div>
          </td>

          <td
            v-for="items in state.sizes"
            :key="items"
            class="contentItem"
            :class="item.goods_art_no === '合计' ? 'total-' + items : ''"
            nowrap
          >
            <div
              v-if="Object.keys(item.skus).includes(items + '')"
              class="width-80"
            >
              <div v-if="item.goods_art_no === '合计'" class="total">
                {{ item.skus[items] }}
              </div>
              <div v-if="item.status !== 0">
                {{ item.skus[items].sku_amount }}
              </div>
              <input
                v-else
                type="number"
                :value="item.skus[items].sku_amount"
                data-value="item.skus[items].sku_amount"
                @keyup="keyup($event)"
                @keyup.enter="save(item.skus[items], items, index, $event)"
                @blur="save(item.skus[items], items, index, $event)"
              />
            </div>
            <div v-else class="width-80">&#45;&#45;</div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>

  <div class="table-td">
    <div class="wrap">
      <table :border="0" cellspacing="0">

        <thead class="headerTop" >
        <tr>
          <th class="headerItem" nowrap>
            <div class="chaochu_1 width-120">货号</div>
          </th>

        </tr>
        </thead>
        <tbody class="analysisBody">
        <tr
          class="tableItem"
          v-for="(item, index) in state.tableData"
          :key="item"
        >
          <td class="contentItem" nowrap @click="openSku(item,index)">

            <img
              v-if="item.goods_art_no !== '合计'"
              class="AnalysisOrderDetailImage"
              :src="item.url"
            />

            <div class="chaochu width-120 goods_name">
              {{ item.goods_art_no }}
            </div>

          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>


  <div class="table-th">
    <table :border="0" cellspacing="0">
      <thead class="headerTop" >
      <tr>
        <th class="headerItem" nowrap>
          <div class="chaochu_1 width-120">货号</div>
        </th>

      </tr>
      </thead>
    </table>
  </div>

  <div class="sys" @click="opensys(1)">扫一扫</div>
  <div class="sys sys2" @click="opensys(2)">扫一扫</div>

  <div class="systck" v-show="state.sysShow">
    <img :src="sys">
  </div>

  <div class="skutck"  v-if="state.skushow" @click='state.skushow = false '>
    <div class="wrap" @click.stop=''>
      <div class="sku-wrap">
        <div class="goods_art_no flex left">
          <div class="img"><img :src="skuData.data.url"></div>
          <div class="info flex-item">
            <div class="price">¥{{formatPrice(skuData.data.unit_price)}}</div>
            <div class="info flex between">
              <div class="flex">
                <div class="color">白色 </div>
                <div class="color mar-lef-10">{{skuData.data.goods_art_no}}</div>
              </div>
              <div style="color: #666">1000</div>
            </div>
          </div>
        </div>
        <div class="store">温州某门店1111</div>
        <div class="flex between">
          <div class="price">
            合计：<span class='c-red'>¥{{formatPrice(skuData.data.price)}}</span><span class="mar-lef-10">共{{skuAmount}}件</span>
          </div>
          <div class="whole flex" >
            <div class='item' @click="whole('reduce')">-</div>
            <div class='item item-whole'>一手码</div>
            <div class='item' @click="whole('add')">+</div>
          </div>
        </div>

        <div class="skus">
          <div class="item"  v-for="item,index in state.sizes" :key="index"
               :class="{'active':index == skuData.index }"
               @click="ontoggle(index)"
          >
            <div class="top">{{item}}</div>
            <div class="input">{{skuData.data.skus[item].sku_amount}}</div>
          </div>
        </div>
      </div>
      <div class="keyword_wrap">
        <div class="shortcutKey flex left">
          <div class="item"  @click="shortcutKey('1:1:1:2:3')">1:1:1:2:3</div>
          <div class="item"  @click="shortcutKey('1:2:3:2:3')">1:2:3:2:3</div>
          <div class="item"  @click="shortcutKey('0:0:0:0:0')">清空全部</div>
        </div>
        <div class="keyword flex between top">
          <div class="left">
            <div class="num" @click="keyword(1)"><div>1</div></div>
            <div class="num" @click="keyword(2)"><div>2</div></div>
            <div class="num" @click="keyword(3)"><div>3</div></div>
            <div class="num" @click="keyword(4)"><div>4</div></div>
            <div class="num" @click="keyword(5)"><div>5</div></div>
            <div class="num" @click="keyword(6)"><div>6</div></div>
            <div class="num" @click="keyword(7)"><div>7</div></div>
            <div class="num" @click="keyword(8)"><div>8</div></div>
            <div class="num" @click="keyword(9)"><div>9</div></div>
            <div class="num" @click="keyword('empty')"><div>清空</div></div>
            <div class="num" @click="keyword(0)"><div>0</div></div>
            <div class="num" @click="keyword('del')"><div>删除</div></div>
          </div>
          <div class="right">
            <div class="num" @click="keyword('prev')"><div>上一个</div></div>
            <div class="num" @click="keyword('next')"><div>下一个</div></div>
            <div class="num save"  @click="keyword('save')"><div>保存</div></div>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
import { reactive, nextTick, computed ,onMounted} from 'vue'
import { useRouter } from 'vue-router'
import img from  './K25211051A0035.jpg'
import sys from  './sys.jpg'
import { Toast } from 'vant'
import { user } from '@/store'


export default {
  name: 'Login',
  components: {
  },
  setup() {
    const router = useRouter()

    const skuData = reactive({
      data:{},
      index:0,
    })

    let skuIndex = 0;
    let scroolTop = 0;
    let initKey = true;


    const state = reactive({
      tableData: [],
      sizes:[225,230,235,240,245],
      sizesSkuAmount: {},     //尺寸
      sysShow:false,
      skushow:false,
    })



    const  formatPrice =  function (
      n,
      options ) {
      const { fractionDigits = 2, skipAuth, removeTailZero } = options ?? {}
      if(!n) return  0.00

      if (typeof n === 'string') n = Number(n)
      if (Number.isNaN(n)) n = 0.00


      return n.toFixed(2)
    }


    onMounted(() => {
      document.querySelector('.tableBox').addEventListener('scroll',function(){
        scroolTop = document.querySelector('.tableBox').scrollTop;
        document.querySelector('.table-td .wrap').scrollTop = scroolTop
      })



      document.querySelector('.table-td .wrap').addEventListener('scroll',function(){
        scroolTop = document.querySelector('.table-td .wrap').scrollTop;
        document.querySelector('.tableBox').scrollTop = scroolTop
      })

      document.querySelector('.titleBox').addEventListener('scroll',function(){
        console.log('bbb')
      })

    })
    const skuAmount = computed(() => {
      let sku_amout = 0
      state.sizes.map((item,index)=>{
        sku_amout += Number(skuData.data.skus[item].sku_amount)
      })
      skuData.data.sku_amout = sku_amout;
      skuData.data.price  =sku_amout * skuData.data.unit_price
      return sku_amout;
    })



    function getDta(){

      let rowData = {
        goods_art_no: 25211051,
        sku_amount:0,
        status: 0 ,
        url: img,
        unit_price:100,
        price:0,
        skus: {
          225: {
            sku_amount: 0,
          },
          230: {
            sku_amount: 0,
          },
          235: {
            sku_amount: 0,
          },
          240: {
            sku_amount: 0,
          },
          245: {
            sku_amount: 0,
          }
        }
      }
      let data = {...rowData}

      data.goods_art_no = parseInt(Math.random()*10000000000)
      return data
    }
    //订货明细
    const addOrder = async (skuData) => {
      let data = [...state.tableData]

      if(skuData){
        if(skuIndex == 0){
          data.push(skuData)
        }else{
          data[skuIndex] = skuData
        }
      }else{
        data.push(getDta())
      }

      let sku_amount = 0
      let price = 0
      let sizesSkuAmount = []
      state.sizes.map((item) => {
        state.sizesSkuAmount[item] = 0
      })
      data.map((item) => {
        if(item.goods_art_no === '合计') return;
        item.sku_amount = 0
        Object.keys(item.skus).map((key) => {
          item.sku_amount += item.skus[key].sku_amount
          state.sizesSkuAmount[key] += item.skus[key].sku_amount
          sku_amount += item.skus[key].sku_amount
          sizesSkuAmount.push({
            sku: key,
            num: item.skus[key].sku_amount,
          })
        })
        item.price = item.unit_price * item.sku_amount
        price += item.price
      })
      let total = {
        skus: { ...state.sizesSkuAmount },
        goods_art_no: '合计',
        price:price,
        sku_amount: sku_amount,
      }
      if(data[0].goods_art_no === '合计'){
        data[0] = total
      }else{
        data.unshift(total)
      }

      state.tableData = data

      nextTick(() => {
        if (sizesSkuAmount.length === 0) return
        sizesSkuAmount.sort((a, b) => b.num - a.num)
        let className = '.total-' + sizesSkuAmount[0].sku

      })
    }
    const addOrder1 = async () => {
      let data = getDta()

    }

    addOrder();
    const save = async (sku, size, index, event) => {
      if (event.currentTarget.value === '') {
        event.currentTarget.value = sku.sku_amount
        return
      }
      let sku_amount = Number(sku.sku_amount)
      let inputValue = Number(event.currentTarget.value)

      if (sku_amount === inputValue) {
        return
      }

      state.tableData[index].skus[size].sku_amount = inputValue


      let diffValue = inputValue - sku_amount
      let diffValuePrice = diffValue*state.tableData[index].unit_price
      //修改当前值
      sku.sku_amount = inputValue
      //修改小计
      state.tableData[index].sku_amount += diffValue
      state.tableData[index].price += diffValuePrice
      //修改合计当前SKU
      let lengthIndex = 0
      state.tableData[lengthIndex].sku_amount += diffValue
      state.tableData[lengthIndex].price += diffValuePrice
      //修改合计总计
      state.tableData[lengthIndex].skus[size] += diffValue
    }
    const keyup = (event) => {
      event.currentTarget.value = event.currentTarget.value.replace(
        /[^\d]/g,
        ''
      )
    }

    const opensys = function(type){
      state.sysShow = true;


      switch (type){
        case 1:
          setTimeout(()=>{

            addOrder();
            Toast(`扫码成功`)
            state.sysShow = false;
          },500)
          break;
        case 2:
          setTimeout(()=>{
            Toast(`扫码成功`)
            initKey = true;
            skuData.data = getDta()
            skuData.index = 0
            skuIndex = 0;
            state.sysShow = false;
            state.skushow = true;
          },500)
          break;
      }
    }
    const  shortcutKey =function (str){
      let valueArr = str.split(':')

      state.sizes.map((item,index)=>{
        skuData.data.skus[item].sku_amount = Number(valueArr[index])
      })


    }
    const  whole =function (type){

      switch (type){
        case  'reduce':
          Object.keys(skuData.data.skus).map(item=>{
            skuData.data.skus[item].sku_amount =  skuData.data.skus[item].sku_amount > 0 ?  skuData.data.skus[item].sku_amount -1 : 0
          })

          break;
        case  'add':
          Object.keys(skuData.data.skus).map(item=>{
            skuData.data.skus[item].sku_amount  =  skuData.data.skus[item].sku_amount < 9999 ?  skuData.data.skus[item].sku_amount + 1 : skuData.data.skus[item].sku_amount
          })

          break;
      }

    }
    const  keyword =function (key){

      let value = skuData.data.skus[state.sizes[skuData.index]].sku_amount+''
      if( key === 'empty'){
        //清空
        skuData.data.skus[state.sizes[skuData.index]].sku_amount = 0 ;
        return ;
      }
      if( key === 'del'){
        //删除
        if(value.length === 1) {
          skuData.data.skus[state.sizes[skuData.index]].sku_amount =  0
          return;
        }
        skuData.data.skus[state.sizes[skuData.index]].sku_amount = value.substr(0,value.length-1)
        return ;
      }
      if( key === 'prev'){
        //上一个
        if(skuData.index > 0) { skuData.index--}
        initKey = true;
        return ;
      }
      if( key === 'next'){
        //下一个
        if(skuData.index < state.sizes.length-1) { skuData.index++}
        initKey = true;
        return ;
      }
      if( key === 'save'){
        //保存
        addOrder(skuData.data);
        state.skushow = false
        return ;
      }

      if(initKey){
        value = Number(key)
      }else{
        value = Number(value+key)
      }
      if(value > 9999 ){
        value = 9999
      }
      skuData.data.skus[state.sizes[skuData.index]].sku_amount = value
      initKey = false;




    }
    const openSku = function(data,index){
      skuData.data = JSON.parse(JSON.stringify(data))
      initKey = true;
      skuData.index = 0
      skuIndex = index;
      state.skushow = true;

    }
    const ontoggle  = function(index){
      skuData.index = index; initKey = true;
    }
    return {
      sys,
      state,
      skuData,
      formatPrice,
      keyup,
      addOrder,
      save,
      opensys,
      shortcutKey,
      whole,
      keyword,
      skuAmount,
      openSku,
      ontoggle,
    }
  },
}
</script>
<style lang="less" scoped>
@import '@/styles/index.less';
.demo-warp { width: 100vw; height: 100vh; overflow: hidden;}
.NotFound {
  height: calc(~'100vh - ' rem(100));
  img {
    width: rem(120);
    height: rem(120);
  }
}
.NotFoundFont {
  color: #999;
  font-size: rem(14);
  text-align: center;
}
.tableHeader {
  background: #f6faff;
  padding: rem(5) 0;
  width: fit-content;
  border-bottom: 2px solid #2c80ed;
  display: table;
}
.item-store {
  height: rem(28);
  font-size: rem(12);
  line-height: rem(14);
}
.title {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  text-align: center;
  color: #333333;
  font-size: rem(16);
  font-weight: 600;
  padding: rem(14) 0;
}
.closeTips {
  color: #666666;
  font-size: rem(13);
  position: absolute;
  right: rem(10);
}
.tipsContent {
  margin: rem(16) rem(10);
  padding: rem(15);
  background: #ffffff;
  border-radius: rem(10);
  font-size: rem(15);
  view {
    margin-bottom: rem(10);
  }
}
.table {
  overflow-x: auto;
  .safe-area-inset-bottom();
}
.tableItem {
  background-color: #f6f6f6;
}
.tableBox {
  overflow: auto;
  position: relative;
  padding-bottom: calc(~'100vh - ' rem(40));
  color: #333333;
  -webkit-overflow-scrolling: auto;
  overflow-scrolling: auto;
}
.tableBoxWechat {
  -webkit-overflow-scrolling: auto;
  overflow-scrolling: auto;

  padding-bottom: calc(~'100vh - ' rem(90));
}
.headerItem {
  text-align: center;
  font-size: rem(14);
  background: #f6faff;
  padding: rem(10) 0;
  border-bottom: 2px solid #2c80ed;
}
.headerTop {
  border-bottom: 2px solid #2c80ed;
}
.tabs {
  word-break: keep-all;
}
.SortImg {
  width: rem(6);
  height: auto;
  margin-left: rem(2);
}
.width-40 {
  width: rem(40);
}
.width-60 {
  width: rem(50);
}
.width-80 {
  width: rem(70);
}
.width-120 {
  width: rem(108);
}
.goods_name {
  box-sizing: border-box;
}
.router {
  font-size: rem(12);
}
tr {
  position: relative;
  /*  td:nth-child(1),th:nth-child(1){
    position: sticky;
    top:0;
    left: 0;
    background: inherit;
  }*/
}
th {
  background: #f6faff !important;
  position: relative;
}
.contentItem {
  text-align: center;
  font-size: rem(14);
  padding: rem(6) 0;
  position: relative;
  td {
    color: #333;
  }
  input {
    width: rem(65);
    border: 1px solid #efefef;
    height: rem(30);
    box-sizing: border-box;
    text-align: center;
    margin: 0 rem(5);
    line-height: rem(30);
    font-size: 16px;
    -webkit-appearance: none;
  }
  .payStatus {
    background: #f56c6c;
    width: rem(35);
    height: rem(20);
    line-height: rem(20);
    font-size: rem(12);
    border-radius: rem(5);
    position: absolute;
    right: 0px;
    top: 0px;
    transform: scale(0.8);
    color: #fff;
  }
}
.tableItem:nth-child(even) {
  background: #ffffff;
}
.linkItem {
  color: @primary;
}
/* 标题栏 */
.purchase-header {
  padding: 0 rem(12);
  position: relative;
  display: flex;
  align-items: center;
  background-color: white;
  border-top: rem(0.5) solid @c-border;
  z-index: 1501;
}

.purchase-header__shop {
  flex-grow: 1;
  font-size: rem(14);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  .icon {
    display: none;
    width: rem(20);
    height: rem(20);
    margin-right: rem(4);
    vertical-align: middle;
  }

  .name {
    vertical-align: middle;
    &::before {
      content: '(';
    }
    &::after {
      content: ')';
    }
  }

  .arrow {
    display: inline-block;
    width: 0;
    height: 0;
    border-width: rem(3.5);
    border-style: solid;
    border-color: #404040 transparent transparent transparent;
  }
}
// 微信环境
.purchase-header--wechat {
  min-height: rem(44);
  padding: 0 rem(24);

  &.purchase-header--no-shop {
    display: none;
  }

  .purchase-header__shop {
    .icon {
      display: inline-block;
    }

    .name {
      &::before {
        content: '';
      }
      &::after {
        content: '';
      }
    }
  }
}
.shop-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: rem(16);
  font-size: rem(14);
  line-height: 1.4;

  & + & {
    border-top: 1px solid rgba(@c-border, 0.25);
  }

  &:active {
    background-color: rgba(black, 0.05);
  }

  .shop-card__name {
    color: @c-black-heavy;
  }
}
.storeSelect {
  margin-left: rem(10);
}
.moneyItem {
  min-width: rem(90);
}
.analysisBody {
  width: 100%;
  position: relative;
  top: rem(0);
}
.titleScroll {
  position: absolute;
  top: rem(0);
  z-index: 101;
}
.titleBox {
  white-space: nowrap;
  z-index: 102;
  word-break: keep-all;
  height: rem(40);
  position: sticky;
  top: 0;
  /* th:nth-child(1){
    z-index: 1000;
  }*/
  tbody {
    height: 1px !important;
    overflow: hidden;
    position: absolute;
    left: -1000px;
    opacity: 0;
    visibility: hidden;
  }
}

.AnalysisOrderDetailImage {
  width: rem(32);
  height: rem(32);
  margin-bottom: rem(2);
}

.sys {
  position: fixed; bottom: 2%; left: 2%;
  width: 60px; height: 60px;
  text-align: center; line-height: 60px;
  font-size: 14px;color: #fff;
  background: rgba(0,0,0,.5);
  border-radius: 100%;
  z-index: 10000;
}
.sys2 {
  right: 2%;
  left: initial;
  background: rgba(255,0,0,.5);
}

.systck {
  position: fixed;
  left: 0;
  right: 0;
  top:0;
  z-index: 10000;
  bottom: 0;

  img { width: 100%; height: 100%; display: block;}
}

.skutck {
  position: fixed;
  background: rgba(0,0,0,.5);
  z-index: 10001;
  right: 0;
  left: 0;
  top:0;
  bottom:0;
  .wrap {
    background: #fff;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    font-size: rem(14);

    .sku-wrap {
      background: #fff;
      padding: rem(10);
      .goods_art_no {
        line-height: rem(20);
        .img,img {
          width: rem(60);
          height: rem(60);
        }
        .info {}
      }
      .store { background: #efefef; height: rem(25); line-height: rem(25); padding: 0 rem(10);
        color:#333;
        border-radius: rem(5);

        margin-bottom: rem(8);
      }
      .price { color: #666;}
      .whole {
        .item {
          color: #333;
          min-width: rem(10);
          height: rem(25);
          padding: 0 rem(8);
          line-height: rem(25);
          text-align: center;
          margin: 0 rem(3);
          background: #efefef;}

        .item-whole { color:#fff; background: @primary; }


      }
      .skus {
        margin-top: rem(8);
        .item{
          display: inline-block;
          width: 20%;
          line-height: rem(25);
          text-align: center;
          .top {
            background: rgba(0,0,0,.5);
            color: #fff;

          }
          .input {
            border: 1px solid rgba(0,0,0,.1);
            border-top:none;
            border-right:none;
          }

          &.active  {
            .top {

              background: @primary;
              color: #fff;
            }
            .input {
              color: #fff;
              background: @primary;
            }
          }
          &:nth-child(5n) .input {
            border-right: 1px solid rgba(0,0,0,.1);
          }


        }
      }
    }
    .keyword_wrap {

      .shortcutKey {
        background: #fff;
        width: 100%;
        padding: 0 rem(10);
        height: rem(40);
        line-height: rem(40);
        overflow-y: auto;

        .item { background: #efefef;
          height: rem(20);
          line-height: rem(20);
          border-radius: 20rem;
          padding: 0 rem(10);
          margin-right: rem(20);
          color: #333;
          &:last-child {
            margin-right: rem(30);
          }
        }
      }
      background: #efefef;
      .keyword {
        padding: 0 0 rem(10) 0;
        .left {
          width: 80%;
          .num {
            width: 33.33%;
            height: rem(45);
            line-height: rem(45);
            text-align: center;
            display: inline-block;
            margin-top: rem(5);

            > div {
              margin-right: rem(5);
              background: #fff;
              font-size: rem(16);
              border-radius: rem(2);
              box-sizing: border-box;
            }
          }

        }
        .right {
          width: 20%;
          .num {
            height: rem(70);
            margin-top: rem(5);
            line-height: rem(70);
            text-align: center;
            width: 100%;
            border-radius: rem(2);
            font-size: rem(16);
            background: #fff;
          }
          .save {
            height: rem(45);
            line-height: rem(45);

            background: @primary;
            color: #fff;
          }
        }
      }
    }
  }
}
.table-td {
  position: absolute;
  left: 0;
  width: rem(108);
  overflow: hidden !important;
  top:0;
  bottom:0;
  z-index: 10000;

  .wrap {
    overflow: auto;
    height:100vh;
    &::-webkit-scrollbar { width: 0 !important }
  }

}
.table-th {
  position: absolute;
  left: 0;
  width: rem(108);
  overflow-x: hidden !important;
  top:rem(0);
  z-index: 10000;

}
</style>
