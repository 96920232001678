import { RouteLocationNormalized, Router } from 'vue-router'
import { device } from '@/store'


/**
 * 首次访问
 * 若没有历史堆 将会先进入 Ready 页
 * 再推入真实访问的路由
 *
 * 当通过后退回到 Ready 页时
 * 将重定向到首页
 *
 * 注意事项：该路由插件应在最末加入
 * @param router
 */
export function firstVisit(router: Router) {

    let isFirstVisit = true
    let lastTo: RouteLocationNormalized

    router.beforeEach((to, from, next) => {
      //小程序 直接跳转
      if(device.isWeChatMini) return next()

      if (isFirstVisit) {
        if (history.state?.back) return next()
        if (to.name === 'Ready') return next()

        lastTo = to
        return next({
          name: 'Ready',
        })
      } else {
        if (to.name === 'Ready') {
          next({
            name: 'Goods',
            force: true,
          })
        }
      }

      return next()
    })

    router.afterEach((to) => {
      if(device.isWeChatMini) return
      if (!isFirstVisit) return
      if (to.name !== 'Ready') return
      isFirstVisit = false

      router.push(
        lastTo ?? {
          name: 'Goods',
        }
      )
    })
}
