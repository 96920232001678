import { computed, readonly, ref, watch } from 'vue'
import { createWaterMarkText, splitText, persistedstate } from '../utils'
import { IUser } from '@/api'

export type IUnread = Record<'refund', number | undefined>

const state = persistedstate(
  'user',
  {
    token: undefined as string | undefined,
    setToken(value?: string) {
      state.token = value
    },

    password: undefined as string | undefined,
    setPassword(value?: string) {
      state.password = value
    },

    userData: undefined as IUser | undefined,
    setUserData(value?: IUser) {
      state.userData = value
    },
    menus: undefined as Object | undefined,
    setMenus(value?: IUser) {
      state.menus = value.supplier_menu
    },

    selectCompany: false as boolean,
    setSelectCompany(value: boolean) {
      state.selectCompany = value
    },

    corpId: undefined as string | undefined,
    setCorpId(value?: string) {
      state.corpId = value
    },

    unread: {} as IUnread,
    setUnread(key: keyof IUnread, value?: number) {
      state.unread[key] = value
    },

    uuid: undefined as string | undefined,
    setUuid(value?: string) {
      state.uuid = value
    },
    loginType: 1 as Number,
    setloginType(value: Number) {
      state.loginType = value
    },
    guideSelect: true as boolean,
    setguideSelect(value: boolean) {
      state.guideSelect = value
    },
    creditGuide: true as boolean,
    setcreditGuide(value: boolean) {
      state.creditGuide = value
    },
    creditShow: true as boolean,
    setcreditShow(value: boolean) {
      state.creditShow = value
    },

    requireTime: undefined as IUser | undefined,
    setrequireTime(value?: IUser) {
      state.requireTime = value
    },
  },
  (state) => {
    const {
      token,
      uuid,
      selectCompany,
      loginType,
      guideSelect,
      creditGuide,
      creditShow,
      requireTime,
    } = state
    console.log(token, '1111')
    return {
      token,
      uuid,
      selectCompany,
      loginType,
      guideSelect,
      creditGuide,
      creditShow,
      requireTime,
    }
  }
)

export const userWatermark = computed(() => {
  const data = state.userData as any
  const values = [
    data?.agent_user?.account_code ?? data?.account_code,
    data?.agent_user?.real_name ?? data?.real_name,
  ]
    .filter((v) => !!v)
    .join(' ')

  return createWaterMarkText(splitText(values || '__title__'))
})

watch(
  () => state.token,
  (token) => {
    if (token) return
    state.setSelectCompany(false)
  },
  {
    immediate: true,
  }
)

export const user = readonly(state)
