import { persistedstate } from '../utils'

const state = persistedstate(
  'purchase',
  {
    isUpdated: false,
    setIsUpdate(value: boolean) {
      state.isUpdated = value
    },

    currentShop: undefined as Number | String | undefined,
    setCurrentShop(value?: Number | String) {
      state.currentShop = value
    },

    lastShop: undefined as Number | String | undefined,
    setLastShop(value?: Number | String) {
      state.lastShop = value
    },
  },
  (state) => {
    const { currentShop, lastShop } = state
    return { currentShop, lastShop }
  }
)

export const purchase = state
