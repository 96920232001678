<template>
  <VanActionSheet
    class="action-sheet"
    :class="{ [`action-sheet--${theme}`]: true }"
    :round="false"
    :closeable="false"
    safe-area-inset-bottom
    v-bind="attrs"
  >
    <template #default v-if="$slots.default">
      <slot name="default" />
    </template>
    <template #description v-if="$slots.description">
      <slot name="description" />
    </template>
    <template #cancel v-if="$slots.cancel">
      <slot name="cancel " />
    </template>
  </VanActionSheet>
</template>

<script lang="ts">
import { defineComponent, PropType, toRefs } from 'vue'
import { ActionSheet } from 'vant'

export default defineComponent({
  name: 'ActionSheet',
  components: {
    VanActionSheet: ActionSheet,
  },
  props: {
    ...({} as {
      title: StringConstructor
      actions: import('vue').PropType<
        import('vant/lib/action-sheet').ActionSheetAction[]
      >
      cancelText: StringConstructor
      description: StringConstructor
      closeOnPopstate: BooleanConstructor
      closeOnClickAction: BooleanConstructor
      closeable: {
        type: BooleanConstructor
        default: boolean
      }
      closeIcon: {
        type: StringConstructor
        default: string
      }
      safeAreaInsetBottom: {
        type: BooleanConstructor
        default: boolean
      }
      show: BooleanConstructor
      zIndex: (NumberConstructor | StringConstructor)[]
      duration: (NumberConstructor | StringConstructor)[]
      teleport: import('vue').PropType<
        string | import('vue').RendererElement | null | undefined
      >
      overlayStyle: import('vue').PropType<import('vue').CSSProperties>
      overlayClass: import('vue').PropType<unknown>
      transitionAppear: BooleanConstructor
      overlay: {
        type: BooleanConstructor
        default: boolean
      }
      lockScroll: {
        type: BooleanConstructor
        default: boolean
      }
      lazyRender: {
        type: BooleanConstructor
        default: boolean
      }
      closeOnClickOverlay: {
        type: BooleanConstructor
        default: boolean
      }
    }),
    theme: {
      type: String as PropType<'default' | 'ios'>,
      default: 'default',
    },
  },
  emits: {
    ...({} as { [key in 'select' | 'cancel' | 'update:show']: null }),
  },
  setup(props, { attrs }) {
    return {
      ...toRefs(props),
      attrs,
    }
  },
})
</script>

<style lang="less">
.action-sheet {
  &--ios {
    padding-left: rem(10);
    padding-right: rem(10);
    margin-top: rem(10);
    margin-bottom: rem(10);
    box-sizing: border-box;
    background-color: transparent;
    .van-action-sheet__gap {
      background-color: transparent;
    }
    .van-action-sheet__cancel {
      border-radius: rem(13);
      color: #3478f6;
      font-weight: 500;
      line-height: rem(22);
    }

    .van-action-sheet__header,
    .van-action-sheet__description,
    .van-action-sheet__item {
      background-color: rgba(white, 0.8);
    }

    .van-action-sheet__content {
      border-radius: rem(13);
    }

    .van-action-sheet__header,
    .van-action-sheet__description {
      border-top-left-radius: rem(13);
      border-top-right-radius: rem(13);
    }

    .van-action-sheet__header {
      & + .van-action-sheet__description {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }
    }

    .van-action-sheet__header,
    .van-action-sheet__description {
      & + .van-action-sheet__content {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        .van-action-sheet__item:first-child {
          border-top: 1px solid rgba(@c-border, 0.5);
        }
      }
    }

    .van-action-sheet__item + .van-action-sheet__item {
      border-top: 1px solid rgba(@c-border, 0.5);
    }

    .van-action-sheet__item {
      color: #3478f6;
      font-weight: 500;
      line-height: rem(22);
    }
  }
}
</style>
