import {
  readonly,
  reactive,
  watch,
  computed,
  unref,
  Ref,
  onActivated,
  onMounted,
  onDeactivated,
  onUnmounted,
} from 'vue'
import { persistedstate } from '@/utils'
import { biz, env } from '@/utils/dingtalk'

export interface IRightTextButtonOptions {
  text: string
  callback: Function
}
const ua = navigator.userAgent
const isAndroid = ua.indexOf('Android') > -1 || ua.indexOf('Adr') > -1
const isIos = /\(i[^;]+;( U;)? CPU.+Mac OS X/.test(ua)

const isMobile = 'ontouchstart' in document
const isPC = !isMobile
const isWeixin =
  navigator.userAgent.toLowerCase().indexOf('micromessenger') != -1
const isWeixinLook = isWeixin
const state = persistedstate(
  'device',
  {
    isMobile,
    isPC,
    isIos,
    isAndroid,
    isDingTalk: !!env.version,
    /* 页面标题 */
    title: '__title__',
    setTitle(value?: string) {
      state.title = value ? value : '__title__'
    },

    /*小程序*/
    isWeChatMini: false,
    setIsWeChatMini(value?: Boolean) {
      state.isWeChatMini = value
    },
    /*小程序 路由模式
    * 1 webview后的页面跳转走小程序的路由
    * 2 webview后的页面跳转走H5路由
    * */
    miniPagetype: 1,
    setMiniPagetype(value?: Boolean) {
      state.miniPagetype = value
    },
    /* 页面背景色 */
    backgroundColor: undefined as string | undefined,
    setBackgroundColor(value?: string) {
      state.backgroundColor = value
    },
    /* 页面置灰 */
    grayscale: false,
    setGrayscale(value: boolean) {
      state.grayscale = value
    },

    /* 右侧按钮 */
    rightTextButton: undefined as IRightTextButtonOptions | undefined,
    setRightTextButton(options?: IRightTextButtonOptions) {
      state.rightTextButton = options
    },

    /* 顶部加载进度条 */
    progress: false,
    setProgress(value: boolean) {
      state.progress = value
    },

    /* 是否微信 */
    isWeixin,
    isWeixinLook,
    setIsWeixinLook(value: boolean) {
      state.isWeixinLook = value
    },

    /* 线上平台政策说明弹窗版本号 */
    version: '',
    setVersion(value: string) {
      state.version = value
    },

    /* 零售好卖榜 提示 */
    hotsaleNotice: true,
    setHotsaleNotice(value: boolean) {
      state.hotsaleNotice = value
    },

    /* 首次访问 */
    firstVisit: true,
    setFirstVisit(value: boolean) {
      state.firstVisit = value
    },

    /* 通用加载状态 */
    loading: false,
    setLoading(value: boolean) {
      state.loading = value
    },
  },
  (state) => {
    const { isWeixinLook, version, hotsaleNotice } = state
    return { isWeixinLook, version, hotsaleNotice }
  }
)

watch(
  () => state.title,
  (title) => {
    if (state.isDingTalk) {
      biz.navigation.setTitle({
        title,
      })
    } else {
      document.title = state.title
    }
  },
  {
    immediate: true,
    flush: 'sync',
  }
)

const setRightTextButtonQueue = reactive<(() => Promise<any>)[]>([])
watch(
  () => state.rightTextButton,
  (options) => {
    if (!state.isDingTalk) return
    if (options) {
      setRightTextButtonQueue.push(() =>
        biz.navigation.setRight({
          show: true,
          control: true,
          text: options.text,
          onSuccess() {
            options.callback()
          },
        })
      )
    } else {
      setRightTextButtonQueue.push(() =>
        biz.navigation.setRight({
          show: false,
          control: false,
        })
      )
    }
  },
  {
    immediate: true,
    flush: 'sync',
  }
)
watch(
  () => setRightTextButtonQueue.length,
  (length) => {
    if (!length) return
    const fn = setRightTextButtonQueue[0]
    fn().then(() => {
      setRightTextButtonQueue.shift()
    })
  }
)

watch(
  () => state.backgroundColor,
  (color) => {
    const body = document.body
    body.style.backgroundColor = color ?? (null as unknown as string)
  }
)

watch(
  () => state.grayscale,
  (grayscale) => {
    const html = document.getElementsByTagName('html')?.[0]
    if (!html) return
    const classList = html.classList
    if (grayscale) {
      classList.add('set-grayscale')
    } else {
      classList.remove('set-grayscale')
    }
  }
)

export const device = readonly(state)

export function useGrayscale(value: boolean | Ref<boolean>) {
  const unrefValue = computed(() => unref(value))
  watch(unrefValue, (value) => device.setGrayscale(value))
  onActivated(() => device.setGrayscale(unrefValue.value))
  onMounted(() => device.setGrayscale(unrefValue.value))
  onDeactivated(() => device.setGrayscale(false))
  onUnmounted(() => device.setGrayscale(false))
}
