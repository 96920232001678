<template>
  <VanNoticeBar
    class="noticebar"
    v-bind="$attrs"
    :text="text"
    wrapable
    :scrollable="false"
  >
    <template #default v-if="$slots.default">
      <slot name="default" />
    </template>

    <template #left-icon>
      <slot name="default" v-if="$slots['left-icon']" />
      <svg
        class="icon"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        v-else
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12ZM10.3461 19.2785C9.57892 19.2785 9.04648 18.9135 9.04648 18.0936C9.04648 17.0669 9.76182 15.3179 9.91817 14.9356L9.91818 14.9355L9.92115 14.9283L11.4129 11.137C11.561 10.7491 11.3706 10.5551 11.1995 10.5551C10.7338 10.5551 10.1871 11.2068 9.73834 11.7416C9.42602 12.1139 9.16117 12.4296 9.00412 12.4296C8.85425 12.4296 8.72722 12.2797 8.72722 12.1509C8.72722 11.7948 9.5181 11.0029 9.86184 10.6588L9.86185 10.6588C9.89247 10.6281 9.91955 10.601 9.9422 10.5781C10.7939 9.7598 11.9031 9.13553 13.1374 9.13553C14.0545 9.13553 15.0349 9.69459 14.266 11.7842L12.7319 15.9846C12.7154 16.0271 12.697 16.0734 12.6775 16.1224C12.549 16.4457 12.3722 16.8906 12.3722 17.1907C12.3722 17.3407 12.4568 17.4905 12.6261 17.4905C13.0066 17.4905 13.5752 16.8444 14.0365 16.3203C14.3512 15.9627 14.616 15.6619 14.7369 15.6619C14.8427 15.6619 14.9926 15.7906 14.9926 15.9846C14.9926 16.6088 12.4992 19.2785 10.3461 19.2785ZM14.0549 7.82532C15.0055 7.82532 15.7762 7.05469 15.7762 6.10407C15.7762 5.15344 15.0055 4.38281 14.0549 4.38281C13.1043 4.38281 12.3337 5.15344 12.3337 6.10407C12.3337 7.05469 13.1043 7.82532 14.0549 7.82532Z"
          fill="currentColor"
        />
      </svg>
    </template>

    <template #right-icon v-if="$slots['right-icon']">
      <slot name="right-icon" />
    </template>
  </VanNoticeBar>
</template>

<script lang="ts">
import { defineComponent, toRefs } from 'vue'
import { NoticeBar } from 'vant'
import Icon from '@/components/Icon'

export default defineComponent({
  name: 'NoticeBar',
  components: {
    VanNoticeBar: NoticeBar,
    Icon,
  },
  props: {
    text: String,
  },
  setup(props) {
    return {
      ...toRefs(props),
    }
  },
})
</script>

<style lang="less" scoped>
.noticebar {
  .icon {
    width: rem(24);
    margin-right: rem(8);
  }
}
</style>
