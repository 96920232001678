<template>
  <a class="calldingtalk" :href="href" @click="onClick">
    <slot name="default"></slot>
  </a>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue'

import { device } from '@/store'
import { dd } from '@/utils'

export default defineComponent({
  name: 'CallMe',
  props: {
    dingtalkId: String,
    userId: String,
  },
  setup(props) {
    const href = computed(() => {
      return `dingtalk://dingtalkclient/action/sendmsg?dingtalk_id=${props.dingtalkId}`
    })

    function onClick(e: Event) {
      if (!device.isDingTalk) return

      e.preventDefault()
      dd.config()
        .then(() => {
          return dd.biz.chat.openSingleChat({
            corpId: dd.corpId,
            userId: props.userId ?? '',
          })
        })
        .catch(() => {
          location.href = href.value
        })
    }

    return {
      href,
      onClick,
    }
  },
})
</script>

<style lang="less" scoped>
.calldingtalk {
  cursor: pointer;
}
</style>
