//钉钉分享
import { device } from '@/store'
import { dd } from '@/utils/index'

export default function (config = {}) {
  if (device.isDingTalk) {
    /* title='好货通',url= window.location.href,content='好货通平台，为客户提供B2B数字化交易功能',image="https://b2b.cnhqt.com/favicon.png"*/
    dd.biz.navigation.setRight({
      show: true, //控制按钮显示， true 显示， false 隐藏， 默认true
      control: true, //是否控制点击事件，true 控制，false 不控制， 默认false
      onSuccess: function (result) {
        //如果control为true，则onSuccess将在发生按钮点击事件被回调
        dd.biz.util.share({
          type: 0, //分享类型，0:全部组件 默认； 1:只能分享到钉钉；2:不能分享，只有刷新按钮
          url: config.url ? config.url : window.location.href,
          content: config.content
            ? config.content
            : '好货通平台，为客户提供B2B数字化交易功能',
          title: config.title ? config.title : '好货通',
          image: config.image
            ? config.image
            : 'https://huilimaimg.cnhqt.com/frontend/logo/logo_yuan.png?x-oss-process=image%2Fresize%2Cm_fill%2Cw_353%2Ch_353%2Fquality%2CQ_80',
          onSuccess: function () {},
          onFail: function (err) {},
        })
      },
      onFail: function (err) {},
    })
  }
}
