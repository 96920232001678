import { IUser } from '@/api'
import dayjs from 'dayjs'
import { http, httpPay } from './http'
import {
  IApiEditPassword,
  IApiFinanceAgent,
  IApiFinanceBrand,
  IApiLogin,
  IApiMenus,
  IApiMineIndex,
  IApiUserDetail,
  IApiUserList,
} from './types'
import { dd } from '@/utils'
import { device, user as userStore } from '@/store'

export async function login(params: {
  username: string
  password: string
  type: number
}) {
  const dingParams: {
    device?: string
    code?: string
  } = {
    device: 'h5',
  }

  if (device.isDingTalk && userStore.corpId) {
    const code = await new Promise<string>((resolve) => {
      dd.ready(async () => {
        try {
          const { code } = await dd.runtime.permission.requestAuthCode({
            corpId: userStore.corpId!,
          })

          resolve(code)
        } catch (error) {
          resolve('')
        }
      })
    })

    if (code) {
      dingParams.code = code
    }
  }

  return http.post<IApiLogin>('/auth/login', {
    ...dingParams,
    ...params,
  })
}
export function dingLogin(params: { code: string }) {
  return http.post<IApiLogin>('/auth/login/dd', params)
}
export function logout() {
  return http.post<void>('/auth/logout', undefined, {
    loading: false,
  })
}
export async function getLoginUser() {
  return http.get<IUser>('/auth/user').then((res) => {
    if (res.data?.is_ejdl) {
      res.data.phone = res.data.agent_phone
        ? res.data.agent_phone
        : res.data.phone
    }
    return res
  })
}
export function getAddressList(params: any) {
  return http.get('/my/addresses', params)
}
export function getLimitAreaList(params: any) {
  return http.get('/my/regionses', params)
}
export function saveAddress(params: any) {
  return http.post('/my/add_address', params)
}
export function editAddress(params: any) {
  return http.post('my/edit_address', params)
}
export function deleteAddress(params: any) {
  return http.post('my/delete_address', params)
}
export function setDefaultAddress(params: any) {
  return http.post('my/set_default_address', params)
}
export function getMenuList(params: any) {
  return http.get('my/menus_list', params)
}
export function getMenuDetail(params: any) {
  return http.get('my/detail_menus', { params })
}
export function addMenu(params: any) {
  return http.post('my/add_menus', params)
}
export function editMenu(params: any) {
  return http.post('my/edit_menus', params)
}
export function deleteMenu(params: any) {
  return http.post('my/delete_menus', params)
}
export function upload(params: any) {
  const form = new FormData()
  Object.keys(params).map((item) => {
    form.append(item, params[item])
  })
  return http.post('/upload', form, {
    cancelRepeatKey: `${Date.now}${Math.random()}`,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}
export function uploadVideo(params: any) {
  const form = new FormData()
  Object.keys(params).map((item) => {
    form.append(item, params[item])
  })
  return http.post('/upload_video', form, {
    cancelRepeatKey: `${Date.now}${Math.random()}`,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}
export function uploadBase64(params: any) {
  return http.post('/base_upload', params)
}
export function getIndex() {
  return http.get<IApiMineIndex>('/my/index').then((res) => {
    if (res.data && res.data.user.agent_user?.id) {
      res.data.user.phone = res.data.user.agent_user.phone
      res.data.user.real_name = res.data.user.agent_user.real_name
      res.data.user.company_name = res.data.user.agent_user.brand_company_name
      res.data.user.company_count = res.data.user.agent_user.company_count
    }
    return res
  })
}
export function getRebate() {
  return http.get('/my/my_rebate', {
    loading: false,
  })
}
export function editDingding(params: { ding_account: string }) {
  return http.post<void>('/my/edit_dingding', params)
}
export function editPassword(params: {
  password: string
  confirm_password: string
  old_password: string
}) {
  return http
    .post<IApiEditPassword>('/auth/reset_password_by_old', params)
    .then((res) => {
      const { data } = res
      if (data) {
        userStore.setToken(data.token)
      }
      return res
    })
}
export function getUserList(
  params: {
    current: number
    keywords?: string
    size?: number
  },
  loading?: boolean
) {
  return http.get<IApiUserList>('/my/user_list', {
    params: {
      size: 10,
      ...params,
    },
    loading,
  })
}
export function getUserDetail(params: { id: string }) {
  return http.get<IApiUserDetail>('/my/user_detail_agent_brand', {
    params,
  })
}
export function getFinanceAgent(params: {
  current: number
  times?: [Date | undefined, Date | undefined]
  keywords?: string
  size?: number
}) {
  let times = params.times
  if (params.times?.every((v) => !v)) {
    times = undefined
  }

  return http.get<IApiFinanceAgent>('/my/finance_agent', {
    params: {
      size: 10,
      ...params,
      times: times?.map((v) => {
        return v ? dayjs(v).format('YYYY-MM-DD HH:mm:ss') : ''
      }),
    },
    loading: false,
  })
}
export function getFinanceBrand(params: {
  current: number
  times?: [Date | undefined, Date | undefined]
  keywords?: string
  size?: number
}) {
  let times = params.times
  if (params.times?.every((v) => !v)) {
    times = undefined
  }

  return http.get<IApiFinanceBrand>('/my/finance_brand', {
    params: {
      size: 10,
      ...params,
      times: times?.map((v) => {
        return v ? dayjs(v).format('YYYY-MM-DD HH:mm:ss') : ''
      }),
    },
    loading: false,
  })
}

/* 未读消息 */
export function unread() {
  return http.get<{
    unread_refund_count: number
  }>('/notice/unread_notice', {
    loading: false,
  })
}

/* 获取政策 */
export function getPolicy() {
  return http.get('/my/policy_list')
}
/* 获取期货投产通知 */
export function getNotice() {
  return http.get('/index/arrival/notice')
}
/* 已读期货投产通知 */
export function readNotice(params: any) {
  return http.post('/index/arrival/notice/read', params)
}

/* 政策已读 */
export function readPolicy(params: any) {
  return http.post('/my/policy_read', params)
}

/* 发送验证码 */
export function sendCode(params: any) {
  return http.post('/auth/send_code', params)
}

/* 忘记密码提交 */
export function codeSubmit(params: any) {
  return http.post('/auth/forget_step_one', params)
}
/* 确认修改 */
export function submitEdit(params: any) {
  return http.post('/auth/forget_step_two', params)
}
/* 获取当前登陆用户所属的组织 */
export function getAccountCompany() {
  return http.get('/backend/account/get_account_company').then((res) => {
    if (!res.error && !res.data) {
      res.data = []
    }
    return res
  })
}

/* 选择所属的组织 */
export function selectCompany(params: any) {
  return http.post('/backend/account/select_company', params)
}

/* 第一次登录修改密码 */
export function firstEdit(params: any) {
  return http.post('/auth/first_edit_password', params)
}

/* 获取用户权限菜单 */
export function getMenus() {
  return http.get<IApiMenus>('/backend/basic/get_current_menu')
}

/* 修改姓名 */
export function editRealName(params: any) {
  return http.post('/auth/edit_real_name', params)
}

/* 获取跳转小程序链接 */
export function redirectWeapp(params: any) {
  return http.post('/note_to_applet', params)
}

/* 获取授信 */
export function getCredits() {
  return http.get('/my/get_credits_info')
}

/* 获取授信 */
export function getCreditBill(params) {
  return http.get('/credit/get_credit_bill_log', { params })
}

/* 获取待还授信订单列表与待还金额 */
export function getCreditBillList(params) {
  return http.get('/credit/get_credit_order_list', { params })
}

/* 获取冻结订单 */
export function getFreezeOrders(params) {
  return http.get('/credit/freeze_orders', { params })
}

/* 释放授信 */
export function releaseCredit(params: any) {
  return http.post('/credit/release_freeze_credit', params)
}

/* 返回客服平台信息 */
export function getServiceInfo() {
  return http.get('/my/get_customer_service_info')
}

/* 获取期货下单协议 */
export function getFuturesHelp() {
  return http.get('/my/get_futures_order_help_rticle')
}

/* 确认协议 */
export function readAgreement() {
  return http.get('/my/read_agreement')
}

/* 获取门店销售报表筛选 */
export function getShopSelection() {
  return http.get('/goods/shop_sale_selection')
}

/* 获取冻结订单 */
export function getSalesReportList(params) {
  return http.get('/goods/shop_sale_list', { params })
}

/* 获取首页拉补推送 */
export function getSelfOrder() {
  return http.get('/index/is_show_self_order')
}


/* 门店库存指标 */
export function getStockTargetApi(params) {
  return http.get('/goods/shop_stock_target', { params })
}


/* 门店进货规划 */
export function getProcurementApi(params) {
  return http.get('/goods/shop_procurement_planning', { params })
}

//订货会订单进度汇总
export function getHDBOrderManage(params) {
  return http.get('/my/order_manage', { params })
}


//订货会 订单筛选汇总
export function getHDBOrderManageScreen(params) {
  return http.get('/my/order_manage/screen', { params })
}

//订货会 /api/my/fair/list

export function getFairList(params, loading = false) {
  return http.get('/my/fair/list', { params, loading })
}


// 扫码（一维码）获取货号
export function getGoodArtNo(str, loading = true) {
  return http.get('/hct/open/sting_search_goods', {
    params: {
      string: str
    },
    loading
  })
}
//获取资讯分类
export function getInformationCateGory(params) {
  return http.get('/company_news/get_category_list', { params })
}
//获取资讯树状分类
export function getInformationTreeCateGory(params) {
  return http.get('/company_news/get_tree_list', { params })
}


//获取资讯列表
export function getInformationList(params) {
  return http.get('/company_news/get_article_list', { params })
}
//获取资讯列表
export function getInformationDetail(params) {
  return http.get('/company_news/detail', { params })
}

/* 会员信息 */
export function getLevelInfo() {
  return http.get('/index/get_company_level_info')
}