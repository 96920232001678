import { Router, useRoute } from 'vue-router'
import { user,device } from '@/store'
import { REDIRECT_KEY, createRedirectTo } from './loginRedirect'
const route = useRoute()
/**
 * 除了注册页，当没有 token 则跳转至注册页
 * @param router
 */
export function authGuard(router: Router) {
  router.beforeEach((to, from, next) => {
    /* 跳过路由守卫 */
    if (to.meta.skipGuard) return next()
    //不是微信小程序环境切换小程序环境
    if(to.query.isWeChatMini && !device.isWeChatMini){
      user.setToken(to.query.token)
      user.setSelectCompany(true)
      device.setIsWeChatMini(true)
      device.setMiniPagetype(to.query.miniPagetype || 1)
    }

    if (user.token /* 已登录 */) {
      if (user.selectCompany /* 已选企业 */) {
        if (to.meta.noAuth /* 不需要权限的页面 */) {
          //未登录允许去未授权页面
          //一开始项目机制有问题；已登录用户不允许去不需要登录的页面
          //改了此处可能会导致某些不可预见的BUG，后续遇到相关问题，在处理
          return next()
      /*    return next({
            name: 'Goods',
          })*/
        }
      } else {
        /* 未选企业 */
        if (to.name !== 'SelectCompany') {
          return next({
            name: 'SelectCompany',
            query: {
              [REDIRECT_KEY]: createRedirectTo(to),
            },
          })
        }
      }
    } else {
      /* 未登录 */
      if (!to.meta.noAuth /* 需要权限的页面 */) {
        return next({
          name: 'Login',
          query: {
            [REDIRECT_KEY]: createRedirectTo(to),
          },
        })
      }
    }

    /* 非首次登录，不允许进入 FirstLogin */
    if (to.name === 'FirstLogin' && !user.password) {
      return next({
        name: 'Goods',
      })
    }

    return next()
  })
}
