<template>
  <IconRegister />
  <MyProgressBar />
  <LoadingToast
    :show="device.loading || userResponse.loading"
    :z-index="99999"
  />

  <!-- 不需 token 的路由 -->
  <router-view v-if="route.meta.noAuth" />
  <!-- 需要 token 的路由 -->
  <router-view v-else-if="route.meta.selectCompany"> </router-view>
  <router-view v-slot="{ Component }" v-else-if="user.userData">
    <keep-alive :include="keepAliveIncludes">
      <component :is="Component" />
    </keep-alive>
  </router-view>
  <Chat />
</template>

<script lang="ts">
import { defineComponent, watch, onBeforeMount, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { Dialog } from 'vant'
import { IconRegister } from '@/components/Icon'
import Chat from '@/components/Chat'
import MyProgressBar from '@/components/MyProgressBar'
import { LoadingToast } from '@/components/Loading'
import { device, user, purchase } from '@/store'
import * as api from '@/api'
import { dd } from '@/utils'
import * as store from '@/store'

export default defineComponent({
  name: 'app',
  components: {
    IconRegister,
    MyProgressBar,
    LoadingToast,
    Chat,
    Dialog: Dialog.Component,
  },
  setup() {
    const router = useRouter()
    const route = useRoute()

    if (device.isDingTalk) dd.config()
    /* keep alive 的路由名称 */
    const keepAliveIncludes = router
      .getRoutes()
      .filter((router) => router.meta?.keepAlive)
      .map((router) => router.name as string)

    /* 获取当前登录用户信息 */
    const userResponse = api.useQuery(() => api.user.getLoginUser(), {
      immediate: false,
      async onError(error) {
        if (user.token) {
          await Dialog.alert({
            title: '获取用户信息失败',
            message: error.message,
            confirmButtonText: '重新请求',
          })
          userResponse.refetch()
        } else {
          api.errorProcessor({ error })
        }
      },
    })

/*    const menusResponse = api.useQuery(() => api.user.getMenus(), {
      onError(error) {
        api.errorProcessor({ error })
      },
    })
    watch(
      () => menusResponse.data,
      (data)=>{
        user.setMenus(data ? data : undefined)
      }
    )*/
    watch(
      () => userResponse.data,
      async (data) => {
        if (data && data.id) {
          window.qimoClientId = {
            userId: data.id, // 自定义用户的唯一id，不能传空字符串、null、中文，id由字母大小写、数字及'-'和 '_'组成
            priority: false, //是否优先进线设置，非必填，值为boolean类型的true或false
            refreshUnreopen: true,
            nickName: data.account_name, //自定义用户昵称，定义用户昵称则userId必传
            customField: {
              用户正式姓名: data.real_name,
              用户手机号: data.phone,
              企业客户编号: data.brand_company_code,
              企业名称: data.brand_company_name,
            },
          }

          if (!device.isWeChatMini) {
            switch (data.partner_code) {
              case '1300':
                //爱依迪
                loadJS(
                  'https://ykf-webchat.7moor.com/javascripts/7moorInit.js?accessId=3c04a180-1319-11ed-b347-d956188fda80&autoShow=false&language=ZHCN'
                )
                break
              default:
                //默认
                loadJS(
                  'https://ykf-webchat.7moor.com/javascripts/7moorInit.js?accessId=4f774f50-131a-11ed-b347-d956188fda80&autoShow=false&language=ZHCN'
                )
                break
            }
          }
          const res = await api.user.getMenus()
          api.errorProcessor({ error:res.error })
          user.setMenus(res.data || undefined)
        }
        user.setUserData(data ? data : undefined)
      }
    )

    /* token，选择组织 更新 重新获取用户信息 */
    watch(
      () => [user.token, user.selectCompany],
      async ([token]) => {
        user.setUserData()
        if (!token) return
        purchase.setLastShop()
        purchase.setCurrentShop()
        userResponse.refetch()
      },
      {
        immediate: true,
      }
    )

    /* 登出 */
    watch(
      () => user.token,
      async (token) => {
        if (token) return
        await router.isReady()
        // 未登录
        router.push({
          name: 'Login',
        })
      }
    )

    /* 未选组织 */
    watch(
      () => [user.token, user.selectCompany],
      async ([token, selectCompany]) => {
        if (token && !selectCompany) {
          await router.isReady()
          router.push({
            name: 'SelectCompany',
          })
        }
      }
    )

    return {
      device,
      user,
      route,
      keepAliveIncludes,
      userResponse,
    }
  },
})
</script>
