import { reactive } from 'vue'
import { persistedstate } from '../utils'

const state = persistedstate(
  'order',
  {
    orders: [] as any[],
    futureorders: [] as any[],
    pay_ordersId: [] as any[],
    orderTips: true as boolean | undefined,
    ordersId: '' as string | undefined,
    TxSN: '' as string | undefined,
    forceFetch: false,
    orderNo: '' as string | number,
    bank: {} as Object,
    setforceFetch(value: boolean) {
      state.forceFetch = value
    },
    setOrders(value: any[]) {
      state.orders = value
    },
    setFutureOrders(value: any[]) {
      state.futureorders = value
    },
    setorderTips(value?: boolean) {
      state.orderTips = value
    },
    setordersId(value?: string) {
      state.ordersId = value
    },
    setTxSN(value?: string) {
      state.TxSN = value
    },
    setPayId(value: any[]) {
      state.pay_ordersId = value
    },
    setorderNo(value: string | number) {
      state.orderNo = value
    },
    setbank(value: string | number) {
      state.bank = value
    },
  },
  (state) => {
    const { orderTips, ordersId, TxSN, orderNo, bank } = state
    return { orderTips, ordersId, TxSN, orderNo, bank }
  }
)

export const orders = state
