<template>
  <span class="my-prices" :class="className">
    <span class="my-prices__placeholder" v-if="isEmpty">{{ placeholder }}</span>

    <span class="my-prices__item" v-else v-for="item in renderList">
      <span class="my-prices__curreny">{{ item.symbol }}￥</span>
      <span class="my-prices__integer">{{ item.integer }}</span>
      <span class="my-prices__decimal" v-if="item.decimal"
        >.{{ item.decimal }}</span
      >
    </span>
    <span
      class="my-prices__range-suffix"
      v-if="!isEmpty && rangeStyle === 'min' && list.length > 1"
      >起</span
    >
  </span>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

const SIZE = {
  s: true,
  m: true,
  l: true,
  'goods-main': true,
  'goods-special': true,
  'goods-futures': true,
  inherit: true,
}

export default defineComponent({
  name: 'Prices',
})
</script>

<script lang="ts" setup>
import { computed, PropType } from 'vue'
import { formatPrice } from '@/utils'
import { user } from '@/store'

const props = defineProps({
  value: {
    type: [String, Number, Array],
  },

  /**
   * 基准字体大小
   * 可选值 参考 SIZE
   * 以样式的形式添加 如 my-prices--size-l
   * 新增时请增加 SIZE 中的值及对应样式
   */
  size: {
    type: String as PropType<keyof typeof SIZE>,
    default: 's',
    validator: __IS_DEV__
      ? function (value) {
          return SIZE[value as keyof typeof SIZE] || false
        }
      : undefined,
  },

  /**
   * 颜色
   * 可选值 'default', 'inherit'
   */
  color: {
    type: String as PropType<'default' | 'inherit'>,
    default: 'default',
  },

  /**
   * 加粗
   */
  bold: {
    type: Boolean,
    default: false,
  },

  /**
   * 使用有大小差异的价格样式
   * 设为 true 时，货币符号与小数将会比主要数字更小
   */
  diffSize: {
    type: Boolean,
    default: false,
  },

  /**
   * 小数处理方式
   * 可选值 'none', 'nozero' , 'all'
   * 分别对应 不显示小数, 去掉末尾的0，强制显示到指定位的小数
   */
  decimalStyle: {
    type: String,
    default: 'nozero',
  },

  /**
   * 价格区间处理方式
   * 可选值 'normal', 'min'
   * 分别对应 ￥10~￥20，￥10起
   */
  rangeStyle: {
    type: String,
    default: 'normal',
  },

  /**
   * 当价格为 null 或 undefined 时的占位内容
   */
  placeholder: {
    type: String,
    default: '业务未报价',
  },

  /**
   * 是否跳过用户权限验证
   **/
  skipAuth: {
    type: Boolean,
    default: false,
  },
})

const className = computed(() => {
  const { size, color, bold, diffSize } = props

  const res = [`my-prices--size-${size}`, `my-prices--color-${color}`]

  if (bold) res.push('my-prices--bold')
  if (diffSize) res.push('my-prices--diff-size')

  return res
})

const list = computed(() => {
  const { value, decimalStyle } = props

  const values = Array.isArray(value) ? value : [value]

  return values.map((v) => {
    const isEmpty = v === undefined || v === null
    const isSee = props.skipAuth || user.userData?.is_see
    if (isEmpty || !isSee) {
      return {
        symbol: '',
        integer: '--',
        decimal: '',
      }
    }

    const price = formatPrice(v as string, {
      fractionDigits: decimalStyle === 'none' ? 0 : undefined,
      removeTailZero: decimalStyle === 'nozero',
      skipAuth: true,
    })

    const [integer = '', decimal = ''] = price.split('.')
    const symbol = integer[0] === '-' ? '-' : ''

    return {
      symbol, // 符号
      integer: symbol === '-' ? integer.slice(1) : integer, // 整数部分
      decimal, // 小数部分
    }
  })
})

const renderList = computed(() => {
  const { rangeStyle } = props
  return rangeStyle === 'min' ? list.value.slice(0, 1) : list.value
})

const isEmpty = computed(() => {
  if (Array.isArray(props.value)) {
    return props.value.every((v) => v === undefined || v === null)
  } else {
    return props.value === undefined || props.value === null
  }
})
</script>

<style lang="less" scoped>
.diff-size (@size: 0.5em) {
  &.my-prices--diff-size {
    .my-prices__curreny,
    .my-prices__decimal,
    .my-prices__range-suffix {
      font-size: @size;
    }

    .my-prices__placeholder {
      font-size: 0.8em;
    }
  }
}

.my-prices {
  /* 尺寸 */
  .diff-size();

  &--size-s {
    font-size: rem(12);
  }
  &--size-m {
    font-size: rem(15);
  }
  &--size-l {
    font-size: rem(18);
  }
  &--size-goods-main {
    font-size: rem(20);
    .diff-size(rem(11));
  }
  &--size-goods-special {
    font-size: rem(20);
    .diff-size(rem(12));
  }
  &--size-goods-futures {
    font-size: rem(20);
    .diff-size(rem(12));
  }
  &--size-goods-coupon {
    font-size: rem(40);
    .diff-size(rem(20));
  }

  /* 颜色 */
  &--color-default {
    color: @c-primary;
  }

  /* 其他 */
  &--bold {
    font-weight: bold;
  }

  .my-prices__item {
    & + .my-prices__item {
      &::before {
        content: ' ~ ';
      }
    }
  }
}
</style>
