import { Router } from 'vue-router'
import router from '@/router/index'
import { setLog } from '@/api/log'
import { device, user } from '@/store'

/*
 * 导出函数,可直接在代码里埋点
 * */
export function setLogInfo(router, describe) {
  setLog({
    ...getFromId(router),
    page: router.meta.title,
    page_url: router.fullPath,
    describe: describe,
  })
}

/*
 * 埋点--操作
 * */
export function lissenLog(app: any) {
  /*
   * 获取具体参数，传参数到后台，
   * 因为vue3.0中用了vue2.0的写法，在v-log中，数据无法响应式,故部分log信息 会绑定到dom的log下
   * */
  function log(el: any, binding: any) {
    return function (e: any) {
      e.stopPropagation()
      const thisRoute = router.currentRoute.value

      let log = binding.value
      if (!log) {
        try {
          log = JSON.parse(el.getAttribute('log'))
        } catch (e) {
          log = {}
        }
      }

      setLogInfo(thisRoute, log.describe)
    }
  }
  /*绑定 v-log 事件 默认为click */
  app.directive('log', {
    mounted(el: any, binding: object) {
      el.addEventListener('click', log(el, binding))
    },
    unmounted(el: any, binding: object) {
      el.removeEventListener('click', log(el, binding))
    },
  })
}

//获取具体页面ID
function getFromId(page) {
  let obj = {}
  switch (page.meta.title) {
    case '商品详情':
      obj.from_id = page.query.id
      break
  }
  return obj
}

/*
 * 埋点--进入页面
 * */
export function log(router: Router) {
  router.beforeEach((to, from, next) => {
    /*
     * 第一次 进入页面
     * */

    if(device.isWeChatMini){
      next()
      return
    }
    if (user.token) {
      if (to.path !== '/' && to.meta.log !== false) {
        setLogInfo(to, {
          action: '进入' + to.meta.title,
          query: { ...to.query },
          from: from
            ? {
                page: from.meta.title,
                page_url: from.fullPath,
              }
            : undefined,
        })
      }
    }
    next()
  })
}
