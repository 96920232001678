<template>
  <van-popup v-model:show="chat.status"  position="bottom" :style="{ height: '90%' }" @before-close='close' >
     <iframe :src="chat.url" v-if="chat.status" class="chat"></iframe>
  </van-popup>
</template>

<script lang="ts">
import { defineComponent, ref, toRefs } from 'vue'
import { chat } from '@/store'
import * as store from '@/store'

import {
  Popup
} from 'vant'
export default defineComponent({
  name: 'Chat',
  components: {
    [Popup.name]: Popup
  },
  props: {
  },
  setup(props, { attrs, emit }) {

    const close = ()=>{

      chat.setStatus(false)
    }

    return {
      chat,
      close
    }
  },
})
</script>

<style lang="less" scoped>
.chat {
  position: absolute;
  left: 0;
  top:0;
  right: 0;
  bottom:0;
  width: 100%;
  height: 100%;

}
</style>
