import { Router } from 'vue-router'
import { Dialog } from 'vant'

/**
 * 路由请求错误（版本更新），刷新页面
 * @param router
 */
export function versionUpdate(router: Router) {
  router.onError(async (error) => {
    if (/Couldn't resolve component/.test(error.message)) {
      // 路由组件加载失败
      // 判定为文件更新，需要刷新页面
      if (__IS_DEV__) return
      await Dialog.alert({
        title: '提示',
        message: '网络异常',
        confirmButtonText: '重新加载',
      })
      location.reload()
    }
    return error
  })
}
