/**
 * 联系人
 * @dingtalkId 非钉钉环境访问唤起会话 钉钉号
 * @userId 钉钉环境访问唤起会话 用户工号 可以通过钉钉的API获取
 *         获取 userId https://open-dev.dingtalk.com/apiExplorer?spm=ding_open_doc.document.0.0.20f2722fnYfvW4#/jsapi?api=biz.contact.complexPicker
 */
export const contact = {
  //全局
  service: {
    dingtalkId: 'qwzvk1u',
    userId: '15825045428205341',
    telephone: '15057338660',
  },
  ertongService: {
    telephone: '15057338660',
  },
  //物流信息
  logistics: {
    dingtalkId: 'mej_j3m8dnm21',
    userId: '16257165342928064',
  },
  ertongLogistics: {
    dingtalkId: 'nhjtg4b',
    userId: '1004465538912765',
  },
}
